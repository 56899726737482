<template>
  <div v-if="loading" class="d-flex align-center justify-center" style="padding-top: 15px;">
    <v-progress-circular indeterminate="indeterminate" color="primary" size="48"></v-progress-circular>
  </div>
  <v-layout v-else column class="pa-4">
    <v-card>
      <v-card-title class="pa-0">
        <v-layout align-center>
          <v-btn color="#292F7D" icon medium @click="openModal('add')" :disabled="readonly_user">
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
          <span class="title" style="color: #292f7d">{{ $t('Add Virtual Device') }}</span>
        </v-layout>
      </v-card-title>
    </v-card>
    <v-layout row wrap class="mt-4">
      <v-card v-if="tableData && tableData.length > 0" style="width:100%" class="blue-rounded-card">
        <v-data-table
          :headers="headers"
          :items="tableData"
          class="rounded-table"
          hide-actions
          :no-data-text="$t('No data available')"
        >
          <template slot="headerCell" slot-scope="props">
            <span :class="lang === 'en' ? 'left' : 'right'">{{ $t(props.header.text) }}</span>
          </template>
          <template v-slot:items="props">
            <tr>
              <td class="text-xs">{{ $t(props.item.name) }}</td>
              <td class="text-xs">{{ $t(props.item['meta.device'].cabinet_id) }}</td>
              <td class="text-xs">{{ $t(props.item['meta.device'].circuit_number) }}</td>
              <td class="text-xs">{{ $t(props.item['meta.location'].lat) }}</td>
              <td class="text-xs">{{ $t(props.item['meta.location'].lng) }}</td>
              <td class="text-xs">
                <v-btn icon :disabled="readonly_user">
                  <v-icon color="grey" @click="openModal('edit', props.item)">edit</v-icon>
                </v-btn>
                <v-btn icon :disabled="readonly_user">
                  <v-icon color="red lighten-2" @click="openModal('delete', props.item)">delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
    <transition name="modal" v-if="modalOpen">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div :class="modalType === 'edit' ? 'modal-container' : 'small-modal-container'">
            <div style="background-color: purple; height: 40px">
              <span class="pt-2" style="position: relative">
                <h2 style="color: white" class="center font-20 font-bold pt-1 mt-0">
                  {{ $t(`${modalType[0].toUpperCase()}${modalType.slice(1)} Virtual Device`) }}
                </h2>
                <v-icon class="closeIcon" color="white" @click="exitModal()">mdi-close</v-icon>
              </span>
            </div>
            <v-layout v-if="modalType === 'add' || modalType === 'edit'">
              <v-flex :xs6="modalType === 'edit'" :xs12="modalType === 'add'">
                <v-layout row wrap>
                  <v-flex xs4 class="pa-3 fieldsStyle">{{ $t('Fixture Name') }}:</v-flex>
                  <v-flex xs8 class="pt-3">
                    <input
                      class="userInputs"
                      type="text"
                      name="fixture_name"
                      id="fixture_name"
                      :value="selectedName"
                      @input="(event) => (selectedName = event.target.value)"
                    />
                  </v-flex>
                  <v-flex xs4 class="pa-3 fieldsStyle">{{ $t('Cabinet ID') }}:</v-flex>
                  <v-flex xs8 class="pt-3">
                    <select v-model="selectedCabinet" class="mt-0 pt-0 userInputs">
                      <option v-for="cabinet in cabinetList" :key="cabinet" :value="cabinet">
                        {{ cabinet }}
                      </option>
                    </select>
                  </v-flex>
                  <v-flex xs4 class="pa-3 fieldsStyle">{{ $t('Circuit Number') }}:</v-flex>
                  <v-flex xs8 class="pt-3">
                    <select v-model="selectedCircuit" class="mt-0 pt-0 userInputs">
                      <option v-for="circuit in circuitList" :key="circuit" :value="circuit">
                        {{ circuit }}
                      </option>
                    </select>
                  </v-flex>
                  <template v-if="modalType === 'edit'">
                    <v-flex xs4 class="pa-3 fieldsStyle">{{ $t('Latitude') }}:</v-flex>
                    <v-flex xs8 class="pt-3">
                      <input
                        class="userInputs"
                        type="text"
                        name="latitude"
                        id="latitude"
                        disabled
                        :value="selectedLat"
                      />
                    </v-flex>
                    <v-flex xs4 class="pa-3 fieldsStyle">{{ $t('Longitude') }}:</v-flex>
                    <v-flex xs8 class="pt-3">
                      <input
                        class="userInputs"
                        type="text"
                        name="longitude"
                        id="longitude"
                        disabled
                        :value="selectedLng"
                      />
                    </v-flex>
                  </template>
                  <v-flex xs12 center>
                    <v-btn
                      v-if="modalType === 'add'"
                      class="btn-rounded font-weight-medium"
                      style="
                        background-color: rgb(129, 46, 129);
                        color: white;
                        font-size: 16px;
                        padding: 0px 10px;
                      "
                      :disabled="selectedName.length === 0"
                      @click="addVirtualDevice"
                    >
                      {{ $t('Add') }}
                    </v-btn>
                    <v-btn
                      v-else
                      class="btn-rounded font-weight-medium"
                      style="
                        background-color: rgb(129, 46, 129);
                        color: white;
                        font-size: 16px;
                        padding: 0px 10px;
                      "
                      :disabled="selectedName.length === 0"
                      @click="editVirtualDevice"
                    >
                      {{ $t('Edit') }}
                    </v-btn>
                    <v-btn
                      class="btn-rounded font-weight-medium"
                      style="
                        background-color: rgb(41, 47, 125);
                        color: white;
                        font-size: 16px;
                        padding: 0px 10px;
                      "
                      @click="exitModal"
                    >
                      {{ $t('Cancel') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 v-if="modalType === 'edit'">
                <MapView
                  @changeVirtualLocation="changeVirtualLocation"
                  :filterDevicesList="[editDevice.id]"
                  :displayOnly="true"
                  :dragOnly="true"
                />
              </v-flex>
            </v-layout>
            <v-layout col v-else-if="modalType === 'delete'">
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex xs12 center>
                    {{ $t('Are you sure you want to delete this device') }}?
                  </v-flex>
                  <v-flex xs12 center>
                    <v-btn
                      class="btn-rounded font-weight-medium"
                      style="
                        background-color: rgb(129, 46, 129);
                        color: white;
                        font-size: 16px;
                        padding: 0px 10px;
                      "
                      @click="deleteDevice(editDevice.id)"
                    >
                      {{ $t('Delete') }}
                    </v-btn>
                    <v-btn
                      class="btn-rounded font-weight-medium"
                      style="
                        background-color: rgb(41, 47, 125);
                        color: white;
                        font-size: 16px;
                        padding: 0px 10px;
                      "
                      @click="exitModal"
                    >
                      {{ $t('Cancel') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
    </transition>
  </v-layout>
</template>
<script>
import vuex from '@/store';
import MapView from '@/pages/mapView/MapView.vue';
import { addVirtualDevice, deleteVirtualDevice } from '@/modules/VirtualDevicesServices';
import API, { Types } from '@/modules/API';
import { setDeviceProperty } from '@/modules/ApiUsers';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import isEqual from 'lodash/isEqual';
import { columns, tondo_columns } from '@/store/modules/Reports';

export default {
  name: 'AddVirtual',
  components: { MapView },
  data() {
    return {
      modalOpen: false,
      headers: [
        { text: 'Fixture Name', value: 'fixtureName', align: 'center' },
        { text: 'Cabinet ID', value: 'cabinetId', align: 'center' },
        { text: 'Circuit Number', value: 'circuit', align: 'center' },
        { text: 'Latitude', value: 'lat', align: 'center' },
        { text: 'longitude', value: 'lng', align: 'center' },
        { text: 'Action', value: 'action', align: 'center' }
      ],
      tableData: [],
      lang: this.$store.state.Global.lang,
      cabinetList: [],
      circuitList: [],
      modalType: '',
      editDevice: {},
      selectedName: '',
      selectedCabinet: '',
      selectedCircuit: '',
      selectedLng: '',
      selectedLat: '',
      loading: true
    };
  },
  mounted() {
    vuex.commit('Global/setPageTitle', 'Virtual Devices');
    this.generatePageData();
  },
  methods: {
    generatePageData(){
      if (this.devices.length !== this.devicesCount) return;
      const devices = this.devices.filter((device) => Utils.hasTondoClass(device.class_name));
      const circuitList = [];
      for (const device of devices) {
        if (device['meta.device'].circuit_number) {
          circuitList.push(device['meta.device'].circuit_number);
        }
      }
      this.circuitList = ['null', ...[...new Set(circuitList)].sort((a, b) => (a > b ? 1 : -1))];
      this.tableData = devices.filter(
        (device) => device['meta.device'].hasOwnProperty('fixture_icon') && device['meta.device'].fixture_icon.toLowerCase() === 'virtual'
      );
      const cabinets = this.devices.filter((device) => Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']));
      const cabinetIds = [];
      for (const cabinet of cabinets) {
        if (cabinet['meta.device'].hasOwnProperty('cabinet_id') && cabinet['meta.device'].cabinet_id !== '') {
          cabinetIds.push(cabinet['meta.device'].cabinet_id);
        }
      }
      this.cabinetList = ['null', ...[...new Set(cabinetIds)].sort((a, b) => (a > b ? 1 : -1))];
      this.loading = false;
    },
    openModal(type, device = {}) {
      this.editDevice = {...device};
      this.modalType = type;
      if (type !== 'delete') {
        if (Object.keys(device).length > 0) {
          this.selectedName = device.name;
          this.selectedCabinet = device['meta.device'].cabinet_id;
          this.selectedCircuit = device['meta.device'].circuit_number;
          this.selectedLng = device['meta.location'].lat;
          this.selectedLat = device['meta.location'].lng;
        } else {
          this.selectedName = '';
          this.selectedCabinet = '';
          this.selectedCircuit = '';
          this.selectedLng = '';
          this.selectedLat = '';
        }
      }
      this.modalOpen = true;
    },
    exitModal() {
      this.editDevice = {};
      this.modalType = '';
      this.modalOpen = false;
    },
    async deleteDevice(id) {
      vuex.commit('Reports/deleteDeviceFromList', id);
      this.tableData = this.tableData.filter((device) => device.id !== id);
      await deleteVirtualDevice(
        this.$store.state.User.project.company,
        this.$store.state.User.project.id,
        id
      );
      this.exitModal();
    },
    async editVirtualDevice() {
      const cabinet_id = this.selectedCabinet || '';
      const circuit_number = this.selectedCircuit || '';

      if (this.selectedName !== this.editDevice.name) {
        this.editDevice.name = this.selectedName;
        const response_ok = await setDeviceProperty(this.editDevice.id, 'meta.name', {name: this.selectedName});

        if (response_ok){
          vuex.commit('Reports/editDeviceField', {device_id: this.editDevice.id, name: 'name', value: this.editDevice.name});
        }
      }

      if (this.editDevice['meta.device'].cabinet_id !== cabinet_id || this.editDevice['meta.device'].circuit_number !== circuit_number){
        const deviceMeta = {...this.editDevice['meta.device']};
        deviceMeta.cabinet_id = cabinet_id;
        const uppercase_cabinet_id = PropUtils.findMatchedUppercaseField(deviceMeta, 'cabinet_id');
        if (uppercase_cabinet_id) deviceMeta[uppercase_cabinet_id] = deviceMeta.cabinet_id;
        deviceMeta.circuit_number = circuit_number;
        const uppercase_circuit_num = PropUtils.findMatchedUppercaseField(deviceMeta, 'circuit_number');
        if (uppercase_circuit_num) deviceMeta[uppercase_circuit_num] = deviceMeta.circuit_number;

        this.editDevice['meta.device'] = deviceMeta;
        const response_ok = await setDeviceProperty(this.editDevice.id, 'meta.device', deviceMeta);
        if (response_ok) {
          vuex.commit('Reports/editDeviceField', {device_id: this.editDevice.id, name: 'meta.device', value: this.editDevice['meta.device']});
        }
      }

      const device_in_table = this.tableData.find((device) => device.id === this.editDevice.id);
      const device_in_table_index = this.tableData.findIndex((device) => device.id === this.editDevice.id);

      if (!isEqual(this.editDevice, device_in_table)){
        this.$set(this.tableData, device_in_table_index, {...this.editDevice});
      }

      this.exitModal();
    },
    async addVirtualDevice() {
      addVirtualDevice(
        this.selectedName,
        this.$store.state.User.project.company,
        this.$store.state.User.project.id
      ).then(async (newDevice) => {
        if (!newDevice) {
          vuex.dispatch('Global/throwNotify', {
            type: 'error',
            title: `${this.$t('Error')}!`,
            text: this.$t(`Couldn't create virtual device`)
          });
          this.exitModal();
          return;
        }

        API.post(
          Types.COMPANIES,
          this.$store.state.User.project.company +
            '/projects/' +
            this.$store.state.User.project.id +
            '/reporting_query/',
          { columns: [...columns.concat(tondo_columns)], where: [`id='${newDevice}'`] },
          {}
        ).then(async (device) => {
          const metaDevice = PropUtils.parseProperty('meta.device', device.results[0]);

          if (this.selectedCabinet !== '' && this.selectedCabinet !== 'null') {
            metaDevice.cabinet_id = this.selectedCabinet;
          }
          if (this.selectedCircuit !== '' && this.selectedCircuit !== 'null') {
            metaDevice.circuit_number = this.selectedCircuit;
          }
          metaDevice.fixture_icon = 'Virtual';
          setDeviceProperty(newDevice, 'meta.device', metaDevice).then(
            async () => {
              const time = new Date().toISOString();
              const instTime = `${time.substring(8, 10)}.${time.substring(5, 7)}.${time.substring(0, 4)} ${time.substring(11, 19)}`;
              const commission = {
                commissioner: this.$store.state.User.username,
                commissioned: true,
                date: instTime
              };
              setDeviceProperty(newDevice, 'meta.commission', commission)
                .then(() => {
                  const editedDevice = device.results[0];
                  editedDevice['meta.device'] = metaDevice;
                  editedDevice['meta.commission'] = commission;
                })
                .then(async () => {
                  API.post(
                    Types.COMPANIES,
                    this.$store.state.User.project.company +
                      '/projects/' +
                      this.$store.state.User.project.id +
                      '/reporting_query/',
                    { columns: [...columns.concat(tondo_columns)], where: [`id='${newDevice}'`] },
                    {}
                  ).then(async (res) => {
                    const defaultLocation = this.$store.state.Props.project_default_location;
                    const string_coords = {lat: defaultLocation.lat.toString(), lng: defaultLocation.lng.toString()};
                    setDeviceProperty(newDevice, 'meta.location', string_coords).then(() => {
                      const final_device = {
                        ...res.results[0],
                        'meta.commission': commission,
                        'meta.location': {...defaultLocation},
                        'meta.device': metaDevice,
                        'lat': defaultLocation.lat,
                        'lng': defaultLocation.lng,
                        'has_valid_location': true
                      };

                      this.tableData.push(final_device);
                      vuex.commit('Reports/addDeviceToList', {...final_device});
                      this.exitModal();
                    });
                  });
                });
            }
          );
        });
      });
    },
    changeVirtualLocation(newLocation) {
      this.selectedLng = newLocation.lng;
      this.selectedLat = newLocation.lat;
      const table = [...this.tableData];
      
      for (const device of table) {
        if (device.id === this.editDevice.id) {
          device['meta.location'] = {lat: this.selectedLat, lng: this.selectedLng};
          device.lat = this.selectedLat;
          device.lng = this.selectedLng;
          break;
        }
      }
      this.tableData = table;
    }
  },
  computed: {
    language() {
      return this.$store.state.Global.lang;
    },
    devices() {
      return this.$store.state.Reports.reportsList;
    },
    devicesCount() {
      return this.$store.state.Reports.devicesCount;
    },
    readonly_user() {
      return this.$store.state.Global.readonly_user;
    }
  },
  watch: {
    language(newVal) {
      this.lang = newVal;
    },
    devices() {
      this.generatePageData();
    },
    devicesCount() {
      this.generatePageData();
    }
  }
};
</script>
<style scoped>
.modal-body {
  margin: 20px 0;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 80%;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: fit-content;
}
.small-modal-container {
  width: 580px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: fit-content;
}
.closeIcon {
  position: absolute;
  top: 8px;
  right: 10px;
  color: white;
  cursor: pointer;
}
.fieldsStyle {
  font-size: 18px;
  font-weight: bold;
}
.userInputs {
  width: 250px;
  height: 24px;
  border: 1px solid grey;
  text-align: left;
}
.selectorStyle {
  width: 250px;
}
</style>