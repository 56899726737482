






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BarChart from '@/components/BarChart.vue';

@Component({
    components: { 
        BarChart
    }
})

export default class TrendsTab extends Vue {

    @Prop() tou_tariff_prop;

    chartSeries = [];
    chartCategories = [];
    chartColors = ['#812E81', '#E57373', '#FFB74D'];

    mounted(){
        const series = [
            {name: this.$t('Peak'), data: []},
            {name: this.$t('High'), dtaa: []},
            {name: this.$t('Low'), dtaa: []}
        ];

        this.chartCategories = Object.keys(this.tou_tariff_prop);
        this.chartCategories.forEach((year) => {
            series[0].data.push(this.tou_tariff_prop[year].peak);
            series[1].data.push(this.tou_tariff_prop[year].high);
            series[2].data.push(this.tou_tariff_prop[year].low);
        });

        this.chartSeries = series;
    }
}
