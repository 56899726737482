



















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Header from '@/mainPage/Header.vue';
import Sidebar from '@/mainPage/Sidebar.vue';
import Error404 from '@/views/Error404.vue';
import GlobalSnackbar from './components/GlobalSnackBar.vue';
import { Global, Props, User, MapSettings, Reports, Groups, ProjectStreams, Projects, Companies } from './store';
import API, { Types } from './modules/API';
import Utils from './modules/Utils';
import Notifications from './components/Notifications.vue';
import PropUtils from './modules/PropUtils';
import moment from 'moment';
import vuex from '@/store';
import store from 'store';
import * as VueGoogleMaps from 'vue2-google-maps';
import config from '@/pages/mapView/components/map/map.config';
import MQTT from '@/modules/MQTT';
import MessageDialog from '@/components/MessageDialog.vue';
import EditUserProfileDialog from '@/components/EditUserProfileDialog.vue';

@Component({
  components: {
    Notifications,
    Header,
    Sidebar,
    GlobalSnackbar,
    Error404,
    MessageDialog,
    EditUserProfileDialog
  }
})
export default class App extends Vue {
  @MapSettings.State('isLoading') isMapLoading;
  @MapSettings.Action('setLoading') setMapLoading;
  @MapSettings.Action('loadUserProperties') loadUserProperties;
  @Groups.Action('load') fetchGroups;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @Global.State('isLoading') isFirstLoading;
  @Global.State('lang') lang;
  @Global.State('isMobile') isMobile;
  @Global.State('notify') notify;
  @Global.State('isSidebarOpen') isSidebarOpen;
  @Global.State('contentStyles') contentStyles;
  @Global.State('timezone') projectTimezone;
  @Global.State('allProjectsTimezone') allProjectsTimezone;
  @Global.State('show_not_allowed_user_dialog') show_not_allowed_user_dialog;
  @Global.Action('setMobile') setMobile;
  @Global.Action('loadDeviceClasses') loadDeviceClasses;
  @Global.Mutation('setNotAllowedUserDialog') setNotAllowedUserDialog;
  @Global.Mutation('setTimezone') setTimezone;
  @Global.Mutation('setIsSidebarOpen') setIsSidebarOpen;
  @Global.Mutation('setContentStyles') setContentStyles;
  @Global.Mutation('setElectricalPowerWindow') setElectricalPowerWindow;
  @Global.Mutation('seHasElectricalPower') seHasElectricalPower;
  @Global.Mutation('setAllProjectsTimezone') setAllProjectsTimezone;
  @Global.Mutation('setMqttVersions') setMqttVersions;
  @User.State('default_map') defaultMap;
  @User.State('project') project;
  @User.State('isAuth') isAuth;
  @User.State('notifications') notifications;
  @User.State('user_profile') user_profile;
  @User.Mutation('setIsLoading') setIsLoading;
  @User.Mutation('logout') logout;
  @User.Action('fetchProjectsData') fetchProjectsData;
  @User.Action('fetchUserProfile') fetchUserProfile;
  @Props.State('list') projectProperties;
  @Props.Mutation('clearMetaDeviceOptions') clearMetaDeviceOptions;
  @Reports.Action('loadReports') loadDevices;
  @Reports.State('reportsList') devices;
  @Reports.Getter('commissionedCabinets') commissionedCabinets;
  @Reports.Mutation('clearDevicesCount') clearDevicesCount;
  @Reports.Action('resetReports') resetReports;
  @ProjectStreams.Action('fetchControlNextActivation') fetchControlNextActivation;
  @ProjectStreams.Action('fetchDigitalInput') fetchDigitalInput;
  @Companies.State('companies_list') companies_list;
  @Companies.Mutation('setSelectedCompany') setSelectedCompany;
  @Companies.Mutation('setCompaniesList') setCompaniesList;
  @Projects.State('list') projects;
  @Projects.State('projects_count') projects_count;
  @Projects.Action('getAlerts') getAlerts;
  @Projects.Action('getEvents') getEvents;

  time = '';
  notification = '';
  language = '';
  mqtt = MQTT.instance;
  all_projects_timezone_calculated = false;
  not_allowed_user_message = 'Oops something went wrong, please login again';
  show_user_profile_dialog = false;

  get containerStyles() {
    return {
      'flex': this.$route.meta.container && this.$route.meta.container.fit,
      'd-flex': this.$route.meta.container && this.$route.meta.container.flex
    };
  }

  async mounted() {
    if (Utils.getIdleTimeInMinutes() >= 30) {
      this.setIsLoading(false);
      this.setMapLoading(false);
      return;
    }
    this.language = this.lang;

    if (!this.projects.length) {
      this.fetchProjectsData();
    }
    const user = store.get('user');
    if (user && user.allowed_companies && !Object.keys(this.companies_list).length){
      if (user.company) {
        this.setSelectedCompany(user.company);
      }
      this.setCompaniesList(user.allowed_companies);
    }
    this.loadProjectData();
    this.onMobileChanged(this.$vuetify.breakpoint.name);
  }

  closeNotification() {
    vuex.commit('User/closeNotification', this.notifications[0].time);
  }

  @Watch('projects.length')
  updateUserLogin(){
    if (this.projects.length === this.projects_count) {
      this.setIsLoading(false);
    }
  }

  @Watch('notifications')
  setNotification() {
    if (this.notifications && this.notifications.length > 0) {
      this.notification = this.notifications[0].message;
    } else {
      this.notification = '';
    }
  }

  @Watch('$route.path')
  setPath(newVal, oldVal) {
    if (newVal !== '/sign' && this.isAuth) {
      vuex.dispatch('User/getProjectNotifications');
    } else {
      this.notification = '';
    }
  }

  @Watch('isAuth')
  updateContentStyles() {
    this.setContentStyles();
  }

  onMobileStateChanged() {
    this.setIsSidebarOpen(!this.isMobile);
    this.setContentStyles();
  }

  onNotAllowedUserDialogClosed(){
    this.logout();
    this.setNotAllowedUserDialog(false);
  }

  @Watch('$vuetify.breakpoint.name')
  onMobileChanged(breakpoint_name) {
    const is_mobile =
      breakpoint_name === 'xs' ||
      breakpoint_name === 'sm' ||
      breakpoint_name === 'md'
        ? true
        : false;
    this.setMobile(is_mobile);
    this.$nextTick(() => this.onMobileStateChanged());
  }

  async loadProjectData(){
    if (this.isAuth && this.project && !this.show_not_allowed_user_dialog) {
      this.getAlerts();
      this.getEvents();
      try {
        this.setMapLoading(true);
        await this.loadUserProperties({ id: this.project && this.project.id });
        this.fetchUserProfile();
        this.updateProjectTimezone();
        this.updateClock();
        this.fetchGroups({ project_id: this.project.id });

        Utils.setDocumentFavicon(this.project.id, this.projectProperties);
        Utils.setDocumentTitle(this.$route, this.project);
        this.setWindow();

        this.resetReports();
        this.clearDevicesCount();
        this.clearMetaDeviceOptions();
        this.loadDevices({ ...this.project });
        if (this.project.id !== 'allProjects') {
          this.fetchDigitalInput();
          this.fetchControlNextActivation();
        }
        this.setMapLoading(false);
      } catch (error) {
        console.log('Err', error);
      }
    }
    this.mqtt.disconnect();
  }

  @Watch('user_profile')
  checkUserProfile() {
    if (this.user_profile && (!this.user_profile.first_name || !this.user_profile.last_name)) {
      this.show_user_profile_dialog = true;
    }else {
      this.checkUserDefaultMap();
    }
  }

  @Watch('show_user_profile_dialog')
  checkUserDefaultMap(){
    if (!this.show_user_profile_dialog && !this.defaultMap) {
      const nextPage = this.project.id === 'allProjects' ? '/general-settings' : '/settings/general';
      this.$router.push(nextPage);
    }
  }

  @Watch('project')
  async onProjectChanged() {
    if (this.isAuth && this.project) {
      this.setMqttVersions(new Map());
      this.loadDeviceClasses();
      this.loadProjectData();
    }
  }

  @Watch('isMapLoading')
  setMapLang(){
    if (!this.isMapLoading){
      Vue.use(VueGoogleMaps, {
        load: {
          key: config.TOKEN_GOOGLE,
          language: this.lang === 'he' ? 'iw' : 'en',
          libraries: ['places', 'visualization', 'geometry']
        }
      });
    }
  }
  
  @Watch('projects')
  async updateProjectTimezone() {
    if (this.projectProperties && this.projectProperties.length) {
      const timezonePropery = PropUtils.getProperty(
        this.projectProperties,
        'project.timezone'
      );
      const value = timezonePropery ? timezonePropery.value.timezone : '';
      if (!value) {
        Utils.showNotify(
          'error',
          'Error!',
          'There is no timezone property in this project. Please contact with support team.'
        );
      } else {
        this.setTimezone(value);
      }
    }else if (this.project && this.project.id === 'allProjects' && !this.allProjectsTimezone && this.projects.length && !this.all_projects_timezone_calculated){
      this.all_projects_timezone_calculated = true;
      let projects_timezone = await this.fetchTimezoneProperty();
      projects_timezone = projects_timezone 
        ? projects_timezone.hasOwnProperty('timezone')
          ? projects_timezone.timezone
          : projects_timezone
        : 'Asia/Tel_Aviv';
        
      this.setAllProjectsTimezone(projects_timezone);
    }
  }

  async fetchTimezoneProperty(){
    const property = await API.get(Types.COMPANIES, `${this.projects[0].company}/${Types.PROJECTS}/${this.projects[0].id}/properties`, {name: 'project.timezone'});
    return property && property.results && property.results[0] && property.results[0].value ? property.results[0].value : '';
  }

  updateClock() {
    if (this.projectTimezone) {
      this.time = moment().tz(this.projectTimezone).format('HH:mm:ss');
      const timeInterval = setInterval(() => {
        if (!this.project) {
          clearInterval(timeInterval);
          return;
        }
        if (this.projectTimezone) {
          this.time = moment().tz(this.projectTimezone).format('HH:mm:ss');
        }
      }, 1000); // run every 1 second
    }
  }

  setWindow() {
    const services_config = PropUtils.getProperty(this.projectProperties, 'services.config');
    const alert_config = services_config && services_config.value && services_config.value.alerts ? services_config.value.alerts : {};
    let window = null;
    if (alert_config) {
      window = alert_config && alert_config.window ? { ...alert_config.window } : null;
      if (window && Object.keys(window).length) {
        window.start = Utils.getDateWithSpecificTime(window.start, +localStorage.getItem('now'));
        window.end = Utils.getDateWithSpecificTime(window.end, +localStorage.getItem('now'));
      }
    }
    this.setElectricalPowerWindow(window);
    this.seHasElectricalPower(Utils.hasElectricalPower());
  }

  @Watch('notify') onGotNotify() {
    if (this.notify) {
      this.$notify({
        type: this.notify.type,
        text: this.notify.text,
        title: this.notify.title
      });
    }
  }

  @Watch('lang')
  updateContentStyle() {
    this.setContentStyles();
    this.language = this.lang;
    if (this.lang !== localStorage.getItem('lang')){
      localStorage.setItem('lang', this.lang);
      window.location.reload();
    }
  }
}
