import { render, staticRenderFns } from "./CabinetAlertsTable.vue?vue&type=template&id=e2fb8d8c"
import script from "./CabinetAlertsTable.vue?vue&type=script&lang=ts"
export * from "./CabinetAlertsTable.vue?vue&type=script&lang=ts"
import style0 from "./CabinetAlertsTable.vue?vue&type=style&index=0&id=e2fb8d8c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports