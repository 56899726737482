<template>
  <v-layout column>
    <v-card class="blue-rounded-card mb-4 header-color">
      <v-data-table
        class="rounded-table"
        :no-data-text="$t('No data available')"
        :headers="headers"
        :items="items"
        hide-actions
      >
        <template slot="items" slot-scope="props">
          <td class="body-2">{{ $t(props.item.text) }}</td>
          <td v-if="props.item.name === 'meta.logo' && props.item.value !== 'Field is empty'" class="body-2">
            <img :src="props.item.value" alt="" class="logo" />
          </td>
          <td v-else class="body-2">{{ $t(props.item.value) }}</td>
          <td>
            <v-btn icon :disabled="readonly_user" @click="OpenDialog(props.item)">
              <v-icon color="grey">edit</v-icon>
            </v-btn>
            <v-btn v-if="props.item.name === 'meta.logo'" icon :disabled="readonly_user" @click="deleteLogo">
              <v-icon color="#e57373">delete</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <transition name="modal" v-if="modalOpen">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="close-modal modalHeader" >
              <div class="center mt-0 pt-1 text-white">
                {{ $t('Edit') + ' ' + $t(editProp.text) }}
              </div>
              <v-icon class="closeIcon" @click="modalOpen = false" color="white">mdi-close</v-icon>
            </div>
            <v-layout
              v-if="editProp.field !== 'url' && editProp.field !== 'timezone'"
              row
              wrap
              class="center pb-4"
            >
              <input
                class="userInputs"
                type="value"
                name="name"
                id="name"
                :value="editProp.value"
                @input="(event) => (editProp.value = event.target.value)"
              />
            </v-layout>
            <v-layout row wrap v-else-if="editProp.field === 'url'">
              <v-flex xs4 class="center mt-0">
                <h3 class="pt-1">{{ $t('Pick input type') }}:</h3>
              </v-flex>
              <v-flex xs8 class="center mt-0" style="flex-basis: 40%">
                <v-select
                  :items="lang === 'en' ? ['file', 'url'] : ['קובץ', 'url']"
                  v-model="fileAddType"
                ></v-select>
              </v-flex>
              <v-flex xs12 class="center mt-0">
                <input
                  type="file"
                  v-if="fileAddType === 'file' || fileAddType === 'קובץ'"
                  name="fileupload"
                  value="fileupload"
                  id="fileupload"
                  @change="(e) => loadFile(e.target.files[0])"
                />
                <div v-else>
                  <span class="text-weight-bold">{{ $t('Url') }}:</span>
                  <input
                    type="value"
                    class="userInputsUrl"
                    name="name"
                    id="name"
                    :value="editProp.value"
                    @input="(event) => (editProp.value = event.target.value)"
                  />
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-else>
              <v-flex xs4 class="center mt-0">
                <h3 class="pt-1">{{ $t('Pick country') }}:</h3>
              </v-flex>
              <v-flex xs6 class="center mt-0">
                <v-select
                  :items="countryList"
                  v-model="countryPick"
                ></v-select>
              </v-flex>
              <v-flex xs4 class="center mt-0" v-if="countryPick.length > 0">
                <h3 class="pt-1">{{ $t('Pick timezone') }}:</h3>
              </v-flex>
              <v-flex
                xs6
                class="center mt-0"
                v-if="countryPick.length > 0 && timeZoneList"
              >
                <v-select
                  :items="timeZoneList"
                  v-model="timeZonePick"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              class="center mt-2"
              v-if="editProp.field === 'url'"
            >
              <v-flex xs4 class="center mt-0">
                <h3>{{ $t('Logo') }}:</h3>
              </v-flex>
              <v-flex xs8>
                <img
                  :src="editProp.value"
                  alt=""
                  style="width: 200px; height: 200px"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap class="center pb-4">
              <v-btn
                :disabled="editProp.value.length === 0 || (editProp.field === 'timezone' && !timeZonePick)"
                class="editBTN btn-rounded font-weight-medium"
                @click="changeProperty"
                color="success"
              >
                {{ $t('Save') }}
              </v-btn>
              <v-btn
                class="cancelBTN btn-rounded font-weight-medium"
                @click="modalOpen = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-layout>
          </div>
        </div>
      </div>
    </transition>
  </v-layout>
</template>

<script>
import Vue from 'vue';
import vuex from '@/store';
import { countries } from '@/modules/countries';
import { setProjectProperty } from '@/modules/ApiUsers';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ProjectConfigurations',
  data() {
    return {
      projectProperties: {
        'Project Timezone': 'project.timezone',
        'Project Location Latitude': 'meta.default_location',
        'Project Location Longitude': 'meta.default_location',
        'Project Logo': 'meta.logo'
      },
      propertyField: ['timezone', 'lat', 'lng', 'url'],
      propertyValues: [],
      modalOpen: false,
      editField: null,
      editProp: null,
      editValue: null,
      editIndex: null,
      lang: this.$store.state.Global.lang,
      fileAddType: null,
      countryList: null,
      countryPick: '',
      timeZoneList: null,
      timeZonePick: null,
      items: []
    };
  },
  mounted() {
    this.fileAddType = this.lang !== 'he' ? 'file' : 'קובץ';
    this.countryList = [...new Set(countries.map((country) => country.name))].sort();
    this.generatePageData();
  },
  methods: {
    generatePageData(){
      this.items = [
        {
          id: '1',
          name: 'project.timezone',
          field: 'timezone',
          text: 'Project Timezone',
          value: 'Field is empty'
        },
        {
          id: '2',
          name: 'meta.default_location',
          field: 'lat',
          text: 'Project Location Latitude',
          value: 'Field is empty'
        },
        {
          id: '3',
          name: 'meta.default_location',
          field: 'lng',
          text: 'Project Location Longitude',
          value: 'Field is empty'
        },
        {
          id: '4',
          name: 'meta.logo',
          field: 'url',
          text: 'Project Logo',
          value: 'Field is empty'
        }
      ];
      this.items.forEach((property) => {
        const property_data = this.$store.state.Props.list.find((project_prop) => project_prop.name === property.name);
        if (property_data && property_data.hasOwnProperty('value')) {
          if (property_data.value && property_data.value[property.field]){
            property.value = cloneDeep(property_data.value[property.field]);
          }
        }else {
          property.value = 'Missing Property';
        }
      });
    },
    OpenDialog(property) {
      this.editProp = cloneDeep(property);
      this.modalOpen = true;
    },
    deleteLogo() {
      setProjectProperty(
        this.$store.state.User.project.company,
        this.$store.state.User.project.id,
        'meta.logo',
        { url: '' }
      ).then((res) => {
        if (res.hasOwnProperty('response')) {
          vuex.commit('Props/edit', res.response);
          vuex.commit('Projects/changeImage', [
            this.$store.state.User.project.id, 
            { url: '@/assets/images/tondo_icons/default_header_logo.svg', color: 'rgba(0, 0, 0, 0.05)'}
          ]);
          const logo_property_index = this.items.findIndex((property) => property.name === 'meta.logo');
          Vue.set(this.items[logo_property_index], 'value', 'Field is empty');
        }
      });
    },
    changeProperty() {
      if (this.editProp.field === 'timezone') this.editProp.value = this.timeZonePick;
      const property_from_server = this.$store.state.Props.list.find((prop) => prop.name === this.editProp.name);

      if (property_from_server && property_from_server.hasOwnProperty('value')) {
        const new_value = cloneDeep(property_from_server.value);
        new_value[this.editProp.field] = this.editProp.value;

        const property_index = this.items.findIndex((property) => property.field === this.editProp.field);
        Vue.set(this.items[property_index], 'value', this.editProp.value);
        try {
          setProjectProperty(
            this.$store.state.User.project.company,
            this.$store.state.User.project.id,
            this.editProp.name,
            new_value
          ).then((res) => {
            if (res.hasOwnProperty('response')) {
              vuex.commit('Props/edit', res.response);
              if (this.editProp.name === 'meta.logo') {
                vuex.commit('Projects/changeImage', [
                  this.$store.state.User.project.id,
                  res.response.value
                ]);
              }else if (this.editProp.name === 'meta.default_location'){
                vuex.commit('Props/setProjectDefaultLocation', res.response.value);
              }
            }
          });
        } catch (e) {
          console.error(e);
        }
        this.modalOpen = false;
      }
    },
    loadFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.editProp.value = reader.result;
        });
        reader.readAsDataURL(file);
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('Field'),
          value: 'property_name',
          class: [this.language === 'en' ? 'text-xs-left body-2' : 'text-xs-right body-2']
        },
        {
          text: this.$t('Value'),
          value: 'property_value',
          class: [this.language === 'en' ? 'text-xs-left body-2' : 'text-xs-right body-2']
        },
        {
          text: this.$t('Action'),
          value: 'id',
          sortable: false,
          class: [this.language === 'en' ? 'text-xs-left body-2' : 'text-xs-right body-2']
        }
      ];
    },
    language() {
      return this.$store.state.Global.lang;
    },
    countryChange() {
      return this.countryPick;
    },
    readonly_user() {
      return this.$store.state.Global.readonly_user;
    }
  },
  watch: {
    language(newVal) {
      this.lang = newVal;
      if (newVal === 'he' && this.fileAddType === 'file') {
        Vue.set(this, 'fileAddType', 'קובץ');
        this.fileAddType = 'קובץ';
      }
      if (newVal === 'en' && this.fileAddType === 'קובץ') {
        Vue.set(this, 'fileAddType', 'file');
        this.fileAddType = 'file';
      }
    },
    countryChange(newVal) {
      this.timeZonePick = null;
      if (newVal) {
        const timezones = [];
        for (const i of countries) {
          if (i.name === newVal) {
            timezones.push(i.timezones);
          }
        }
        this.timeZoneList = timezones.flat();
        this.timezonePick = this.timeZoneList[0];
      }
    }
  }
};
</script>
<style scoped>
.config-headers {
  font-size: 16px;
  font-weight: bold;
}
.logo {
  width: 50px;
  height: 50px;
}
.field {
  font-size: 14px;
}
.value {
  font-size: 14px;
}
.redIcon {
  color: #e57373;
}
.icon {
  font-size: 24px;
  cursor: pointer;
}
.modal-body {
  margin: 20px 0;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 500px;
  margin: 0px auto;
  height: fit-content;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.closeIcon {
  position: absolute;
  top: 7px;
  right: 15px;
  color: black;
  cursor: pointer;
}
.close-modal {
  position: relative;
  height: 45px;
}
.modalHeader {
  font-size: 24px;
  font-weight: bold;
  background-color: purple;
}
.userInputs {
  width: 200px;
  height: 24px;
  border: 1px solid grey;
  margin-left: 10px;
  text-align: left;
  padding-left: 5px;
}
.userInputsUrl {
  width: 300px;
  height: 24px;
  border: 1px solid grey;
  margin-left: 10px;
  text-align: left;
  padding-left: 5px;
}
.editBTN {
  background-color: rgb(129, 46, 129) !important;
  font-family: 'Open Sans', 'Arial', monospace !important;
  color: white;
}
.cancelBTN {
  background-color: rgb(41, 47, 125) !important;
  font-family: 'Open Sans', 'Arial', monospace !important;
  color: white;
}
.text-weight-bold {
  font-weight: bold;
}
.borderCard{
  border: 1px solid #332a8f;
  border-radius: 20px;
}
</style>