var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('div',{staticClass:"blue-rounded-card pa-2 table-style"},[_c('v-data-table',{staticClass:"rounded-actions-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.filtered_table,"pagination":_vm.pagination,"no-data-text":_vm.$t('No data available'),"rows-per-page-text":((_vm.$t('Rows per page')) + ":")},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_vm._l((props.headers),function(header){return [(header.sortable)?_c('th',{key:header.text,staticClass:"select-style",class:[
                'column sortable',
                'body-2',
                _vm.sortBy === header.sort_field ? _vm.descending ? 'desc' : 'asc' : '',
                header.sort_field === _vm.sortBy ? 'active' : ''
              ],style:(_vm.lang === 'en' ? 'text-align: left !important' : 'text-align: right !important'),on:{"click":function($event){return _vm.changeSort(header)}}},[_vm._v(" "+_vm._s(_vm.$t(header.text))+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_downward")])],1):_c('th',{key:header.text,staticClass:"column body-2",style:(_vm.lang === 'en' ? 'text-align: left !important' : 'text-align: right !important')},[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]})],2),_c('tr',[_vm._l((_vm.headers),function(header){return [(header.select)?_c('th',{key:header.text,staticClass:"column body-2 autocomplete-style",style:(_vm.lang === 'en' ? 'text-align: left !important' : 'text-align: right !important')},[_c('v-autocomplete',{staticClass:"pt-0",staticStyle:{"min-height":"20px !important","min-width":"110px !important"},attrs:{"items":_vm.filters[header.search_field].options,"search-input":_vm.filters[header.search_field].search_value,"multiple":_vm.filters[header.search_field].multiple,"menu-props":{
                          bottom: true,
                          offsetY: true,
                          contentClass: _vm.lang === 'en' ? 'menu-content-en' : 'menu-content-he',
                          maxHeight: 110,
                          closeOnContentClick: true
                      },"placeholder":((_vm.$t('Search')) + "..."),"hide-details":"","clearable":"","chips":"","attach":"","outline":"","no-data-text":_vm.$t('No data available')},on:{"update:searchInput":function($event){return _vm.$set(_vm.filters[header.search_field], "search_value", $event)},"update:search-input":function($event){return _vm.$set(_vm.filters[header.search_field], "search_value", $event)},"change":function($event){return _vm.filterColumn(header.search_field)}},model:{value:(_vm.filters[header.search_field].selected),callback:function ($$v) {_vm.$set(_vm.filters[header.search_field], "selected", $$v)},expression:"filters[header.search_field].selected"}})],1):_c('th',{key:header.text})]})],2)]}},{key:"items",fn:function(props){return [_c('tr',[_vm._l((_vm.headers.slice(0, -1)),function(header){return [_c('td',{key:header.text},[_vm._v(_vm._s(props.item[header.search_field]))])]}),_c('td',[_c('v-icon',{on:{"click":function($event){return _vm.openIncident(props.item)}}},[_vm._v("visibility")])],1)],2)]}}],null,false,3194729029)})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }