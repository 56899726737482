








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, ProjectStreams } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import Utils from '@/modules/Utils';

@Component({
    components: {
    }
})
export default class OutputBox extends Vue{
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @ProjectStreams.State('next_activation_by_cabinet') next_activation_by_cabinet;

    @Prop() device_id;
    @Prop() output;
    @Prop() output_connected;
    @Prop() output_schedule;
    @Prop() dusk_dawn_data;

    on_time = '';
    off_time = '';
    next_activation_times = [];

    mounted(){
        if (this.next_activation_by_cabinet.has(this.device_id)){
            this.next_activation_times = this.next_activation_by_cabinet.get(this.device_id)
                .filter(([timestamp, value]) => value && value.output === this.output.output_num);
        }
        this.setOnOffTime();
    }

    @Watch('dusk_dawn_data')
    @Watch('next_activation_by_cabinet')
    setOnOffTime(){
        this.on_time = this.getOnTime();
        this.off_time = this.getOffTime();
    }

    getOnTime(){
        if (this.next_activation_times.length) {
            const on_time = this.next_activation_times[this.next_activation_times.length - 1][1].on_time;
            return on_time.split(' ')[1].slice(0, 5);
        }

        if (!this.dusk_dawn_data) return '';

        return this.output_schedule.is_astronomical
            ? Utils.getAstronomicalTime(this.output_schedule.dusk, this.dusk_dawn_data.sunset_start_timestamp, this.dusk_dawn_data.sunset_start, this.projectTimezone)
            : this.output_schedule.on;
    }

    getOffTime(){
        if (this.next_activation_times.length) {
            const off_time = this.next_activation_times[this.next_activation_times.length - 1][1].off_time;
            return off_time.split(' ')[1].slice(0, 5);
        }

        if (!this.dusk_dawn_data) return '';

        return this.output_schedule.is_astronomical
            ? Utils.getAstronomicalTime(this.output_schedule.dawn, this.dusk_dawn_data.sunrise_timestamp, this.dusk_dawn_data.sunrise, this.projectTimezone)
            : this.output_schedule.off;
    }

    get connected(){
        return this.is_status_opposite
            ? !this.output_connected
            : this.output_connected;
    }

    get is_status_opposite(){
        return this.output.configuration && this.output.configuration.includes('1=off');
    }
}

