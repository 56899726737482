<template>
  <v-layout v-if="loading" class="d-flex align-center justify-center" style="padding-top: 15px">
    <v-progress-circular indeterminate="indeterminate" color="primary" size="48"></v-progress-circular>
  </v-layout>
  <div class="mb-2" v-else>
    <div v-if="!reportOpen" class="pa-4">
      <v-layout column class="my-4">
        <v-layout row>
          <v-flex shrink>
            <v-icon large @click="$router.push('/reports/list')">{{ lang === 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right'}}</v-icon>
          </v-flex>
          <v-flex grow>
            <v-layout justify-center align-center>
              <h1>{{ $t('Installation Monitoring Report') }}</h1>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout row class="my-2">
        <v-flex>
          <div class="tabs">
            <button @click="setView('installations')" :class="[tab === 'installations' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Weekly Devices Installation') }}
            </button>
            <button @click="setView('cabinet')" :class="[tab === 'cabinet' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Report by Cabinet') }}
            </button>
            <button @click="setView('commissioner')" :class="[tab === 'commissioner' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Report by Installer') }}
            </button>
            <button @click="setView('dates')" :class="[tab === 'dates' ? 'button-active' : '', lang === 'en' ? 'mr-2' : 'ml-2']">
              {{ $t('Report by Dates') }}
            </button>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xl12>
          <DailyCommissioned v-if="tab === 'installations'"/>
          <v-card v-else class="blue-rounded-card pa-3" style="background-color: white !important">
            <v-layout v-if="tab === 'cabinet' && !cabinetsList.length" row wrap justify-center>
              <div class="red--text errorTextBold">
                {{ $t('No cabinets available for this project') }}
              </div>
            </v-layout>
            <v-layout v-else-if="tab === 'commissioner' && !commissionersList.length" row wrap justify-center>
              <div class="red--text errorTextBold">
                {{ $t('No installers available for this project') }}
              </div>
            </v-layout>
            <v-layout v-else-if="tab === 'dates' && !cabinetsList.length && !commissionersList.length" row wrap justify-center>
              <div class="red--text errorTextBold">
                {{ $t('No cabinets and installers available for this project') }}
              </div>
            </v-layout>
            <template v-else>
              <v-layout row wrap class="autocomplete-style">
                <v-flex xs12 lg5 v-if="tab === 'cabinet'">
                  <v-layout justify-center style="margin-bottom: 40px" class="mt-2 selectHeaderMonitoring">
                    {{ $t('Select Cabinets') }}
                  </v-layout>
                  <v-form ref="form" class="px-4" @submit.prevent>
                    <v-autocomplete
                      v-model="queryCabinets"
                      :items="cabinetsList"
                      multiple
                      clearable
                      attach
                      menu-props="bottom, offsetY"
                      outline
                      chips
                      :search-input.sync="searchValue"
                      :rules="[(value) => (value && value.length > 0) || $t('Required field')]"
                      :no-data-text="$t('No data available')"
                      @change="searchValue = ''"
                    ></v-autocomplete>
                  </v-form>
                  <v-layout justify-center>
                    <button class="selectionBtn mx-4" @click="queryCabinets = cabinetsList">
                      {{ $t('Select All') }}
                    </button>
                    <button class="selectionBtn" @click="queryCabinets = []">
                      {{ $t('Clear Selection') }}
                    </button>
                  </v-layout>
                </v-flex>
                <v-flex xs12 lg5 v-else-if="tab === 'commissioner'">
                  <v-layout justify-center style="margin-bottom: 40px" class="mt-2 selectHeaderMonitoring">
                    {{ $t('Select Installer') }}
                  </v-layout>
                  <v-form ref="form" class="px-4" @submit.prevent>
                    <v-autocomplete
                      v-model="queryCommissioners"
                      :items="commissionersList"
                      multiple
                      clearable
                      attach
                      menu-props="bottom, offsetY"
                      outline
                      chips
                      :search-input.sync="searchValue"
                      :rules="[(value) => (value && value.length > 0) || $t('Required field')]"
                      :no-data-text="$t('No data available')"
                      @change="searchValue = ''"
                    ></v-autocomplete>
                  </v-form>
                  <v-layout justify-center>
                    <button class="selectionBtn mx-4" @click="queryCommissioners = commissionersList">
                      {{ $t('Select All') }}
                    </button>
                    <button class="selectionBtn" @click="queryCommissioners = []">
                      {{ $t('Clear Selection') }}
                    </button>
                  </v-layout>
                </v-flex>
                <v-flex xs12 lg5 v-else-if="tab === 'dates'" class="dates-tab-select-style">
                  <v-layout justify-center style="margin-bottom: 40px" class="mt-2 selectHeaderMonitoring">
                    {{ $t('Select Tables Sorting Method') }}
                  </v-layout>
                  <v-form ref="form" class="px-4" @submit.prevent>
                    <v-autocomplete
                      v-model="sortBy"
                      :items="sortList"
                      attach
                      menu-props="bottom, offsetY"
                      outline
                      :search-input.sync="searchValue"
                      :rules="[(value) => !!value || $t('Required field')]"
                      :no-data-text="$t('No data available')"
                      @change="searchValue = ''"
                    ></v-autocomplete>
                  </v-form>
                </v-flex>
                <v-flex xs12 lg7>
                  <v-layout justify-center class="mt-2 mb-0 selectHeaderMonitoring">
                    {{ $t('Select Dates') }}
                  </v-layout>
                  <v-layout class="mt-3">
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('From') }}</h3>
                      <DatePicker
                        @dateChanged="setStartDate"
                        :date="start_date"
                      />
                    </v-layout>
                    <v-layout column justify-center align-center>
                      <h3>{{ $t('To') }}</h3>
                      <DatePicker @dateChanged="setEndDate" :date="end_date" />
                    </v-layout>
                  </v-layout>
                  <v-layout row justify-center align-center>
                    <v-btn color="grey" class="white--text" @click="setYesterday">{{ $t('Yesterday') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastWeek">{{ $t('Last Week') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastMonth">{{ $t('Last Month') }}</v-btn>
                    <v-btn color="grey" class="white--text" @click="setLastYear">{{ $t('Last Year') }}</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </template>
            <v-layout row class="mt-3 mx-4 generate-btn-style">
              <v-btn
                round
                dark
                :class="lang === 'en' ? 'ml-auto' : 'mr-auto'"
                color="#812E81"
                @click="openReport"
                :disabled="
                  period === null && tab !== 'dates' ||
                  tab === 'dates' && !sortBy ||
                  tab === 'cabinet' && queryCabinets.length === 0 ||
                  tab === 'commissioner' && queryCommissioners.length === 0
                "
                >{{ $t('Generate Monitoring Report') }}</v-btn
              >
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div v-else class="printElement pa-4">
      <v-layout column class="my-4">
        <v-layout row>
          <v-flex shrink>
            <v-icon large @click="closeReport">{{ lang === 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
          </v-flex>
          <v-flex grow>
            <v-layout justify-center align-center>
              <h1>{{ $t('Installation Monitoring Report') }}</h1>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 class="mt-3 btnsHide">
          <ExportData
            v-if="exportAllData.length > 0"
            :data="exportAllData"
            :text="$t('Export All devices')"
            color="indigo accent-4"
            :sourceTitle="`Installation Monitoring Report- All devices - Dates:${timeStart}-${timeEnd}`"
            :removeDate="true"
            :noParsing="true"
          />
          <ExportData
            v-if="exportData.length > 0"
            :data="exportData"
            :text="$t('Export Installation Fail')"
            color="indigo accent-4"
            :sourceTitle="`Installation Monitoring Report- Installation Fail - Dates:${timeStart}-${timeEnd}`"
            :removeDate="true"
            :noParsing="true"
            class="mx-2"
          />
          <v-btn 
            style="color: #304ffe; border: 1px solid #304ffe; background-color: #f2f4f8;"
            :class="exportData.length > 0 ? 'mx-0' : 'mx-2'"
            @click="print"
            >{{ $t('Print') }}</v-btn
          >
        </v-flex>
      </v-layout>
      <VirtualList
        class="monitoring-report-data"
        style="height: 100%; overflow-y: auto;"
        data-key="cabinet_id"
        :data-sources="viewedTableData"
        :data-component="monitoring_report_item"
        :extra-props="{
          filterDevicesList,
          mapOpen,
          viewedTableData,
          tab,
          sortBy,
          timeStart,
          timeEnd,
          realCabinetsList,
          openCabinetPage,
          headers,
          commissionersList,
          cabinetsList,
          changeIndex,
          cabinetsFullData,
          resetChange,
          selectMapCommissioner,
          selectMapCabinet,
          selectMapDevices,
          selectCol
        }"
      />
      <v-layout row wrap justify-center v-if="viewedTableData.length === 0" class="noDataAvail">
        {{ $t('No data available for this selection') }}
      </v-layout>
    </div>
    <v-layout v-if="cabinetPage">
      <v-dialog v-model="cabinetPage" persistent>
        <FullViewTab
          style="background-color: white !important"
          :device="cabinetData"
          :cabinetDevices="cabinetDevices"
          :closeBtn="true"
          @close="cabinetPage = false"
        />
      </v-dialog>
    </v-layout>
  </div>
</template>
<script>
import DatePicker from '@/pages/reports/components/DatePicker.vue';
import FullViewTab from '@/pages/mapView/components/MapTabs/information/hubTabs/FullViewTab.vue';
import moment from 'moment-timezone';
import ExportData from '@/components/ExportData.vue';
import MapMixin from '../pages/mapView/components/MapTabs/devices/TabsInfoMixin.ts';
import Vue from 'vue';
import Utils from '@/modules/Utils';
import DailyCommissioned from '@/pages/reports/DailyCommissioned.vue';
import VirtualList from 'vue-virtual-scroll-list';
import MonitoringReportItem from '@/pages/reports/components/MonitoringReportItem.vue';

export default {
  name: 'MonitoringReport',
  components: {
    DatePicker,
    ExportData,
    FullViewTab,
    DailyCommissioned,
    VirtualList,
    MonitoringReportItem
  },
  mixins: [MapMixin],
  data() {
    return {
      tab: 'cabinet',
      searchValue: '',
      commissionersList: [],
      cabinetsList: [],
      queryCabinets: [],
      queryCommissioners: [],
      start_date: null,
      end_date: null,
      set_default: true,
      reportOpen: false,
      projectStartTime: null,
      projectEndTime: null,
      deviceList: null,
      tableData: null,
      viewedTableData: null,
      timeStart: null,
      timeEnd: null,
      datesChange: false,
      commissionersChange: false,
      cabinetsChange: false,
      exportData: null,
      exportAllData: null,
      filterDevicesList: null,
      period: 'all',
      sortBy: '',
      mapOpen: null,
      allCabinets: null,
      changeIndex: null,
      realCabinetsList: null,
      allDevices: null,
      cabinetPage: false,
      cabinetDevices: null,
      cabinetData: null,
      loading: true,
      monitoring_report_item: MonitoringReportItem
    };
  },
  mounted() {
    this.reportOpen = false;
    this.$store.commit('Global/setPageTitle', 'Installation Monitoring Report');
    this.generatePageData();
    if (this.sortList.length) {
      if (this.sortList.find((item) => item.value === 'cabinet')) {
        this.sortBy = 'cabinet';
      }else {
        this.sortBy = 'installer';
      }
    }
  },
  methods: {
    validateForm() {
      this.$nextTick(() => this.$refs.form && this.$refs.form.validate());
    },
    generatePageData(){
      if (this.devices.length !== this.devicesCount) return;
      const allCabinets = this.devices.filter((device) => Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']));
      const allDevices = this.devices.filter((device) => Utils.hasTondoClass(device.class_name));
      const cabinets = [];
      const realCabinets = [];
      for (const dev of allDevices) {
        if (dev['meta.device'].hasOwnProperty('cabinet_id') && dev['meta.device'].cabinet_id.length > 0) {
          cabinets.push(dev['meta.device'].cabinet_id);
        }
      }
      for (const cabinet of allCabinets) {
        if (cabinet['meta.device'].hasOwnProperty('cabinet_id') && cabinet['meta.device'].cabinet_id.length > 0) {
          realCabinets.push(cabinet['meta.device'].cabinet_id);
        }
      }
      this.allCabinets = allCabinets;
      this.cabinetsList = [...new Set(cabinets)];
      this.realCabinetsList = [...new Set(realCabinets)];
      this.cabinetsFullData = JSON.parse(JSON.stringify(allCabinets));
      this.tab = 'installations';
      this.setDefaultDates();
      this.getDevices();
      this.loading = false;
    },
    openCabinetPage(cabinetId) {
      const cabinet = this.cabinetsFullData.find((cabinet) => cabinet['meta.device'].cabinet_id === cabinetId);
      this.cabinetData = cabinet;
      const devices = [];
      for (const device of this.allDevices) {
        if (device['meta.device'].hasOwnProperty('cabinet_id') && device['meta.device']['cabinet_id'] === cabinetId) {
          devices.push(device);
        }
      }
      this.cabinetDevices = devices;
      this.cabinetPage = true;
    },
    open(index) {
      this.selectCol(index, 'Installed Devices', !this.mapOpen[index]);
      Vue.set(this.mapOpen, index, !this.mapOpen[index]);
      // this.mapOpen[index] = !this.mapOpen[index];
    },
    changeDates() {
      this.datesChange = !this.datesChange;
    },
    changeCommissioner() {
      this.commissionersChange = !this.commissionersChange;
    },
    changeCabinets() {
      this.cabinetsChange = !this.cabinetsChange;
    },
    getString(arr) {
      for (let i = 0; i < arr.length; i++) {
        arr[i] = this.$t(arr[i]);
        arr[i] = this.$t(arr[i]);
      }
      return arr;
    },
    setView(view) {
      this.tab = view;
    },
    clearAll() {
      this.queryCabinets = [];
    },
    openReport() {
      const allCabinets = this.devices.filter((device) => Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']));
      this.allCabinets = allCabinets;
      const startDate = new Date(
        this.start_date.substring(0, 4),
        parseInt(this.start_date.substring(5, 7), 10) - 1,
        this.start_date.substring(8, 10)
      ).getTime();
      this.timeStart = new Date(startDate).toLocaleDateString();
      const endDate = new Date(
        this.end_date.substring(0, 4),
        parseInt(this.end_date.substring(5, 7), 10) - 1,
        this.end_date.substring(8, 10),
        23,
        59,
        59
      ).getTime();
      this.timeEnd = new Date(endDate).toLocaleDateString();
      let tempTable = [...this.tableData];
      for (let t = tempTable.length - 1; t > -1; t--) {
        if (tempTable[t].date !== 'unspecified') {
          if (tempTable[t].date < startDate || tempTable[t].date > endDate) {
            tempTable.splice(t, 1);
          }
        }
      }
      if (this.tab === 'cabinet') {
        tempTable = tempTable.filter(
          (row) => this.queryCabinets.indexOf(row.cabinet) > -1
        );
      }else if (this.tab === 'commissioner') {
        tempTable = tempTable.filter(
          (row) => this.queryCommissioners.indexOf(row.commissioner) > -1
        );
      }
      const splitTables = [];
      if (this.tab === 'cabinet' || (this.tab === 'dates' && this.sortBy === 'cabinet')) {
        let cabinets = [];
        for (const j of tempTable) {
          cabinets.push(j.cabinet);
        }
        cabinets = [...new Set(cabinets)];
        for (const z of cabinets) {
          splitTables.push(tempTable.filter((row) => row.cabinet === z));
        }
      } else {
        let commissioner = [];
        for (const j of tempTable) {
          commissioner.push(j.commissioner);
        }
        commissioner = [...new Set(commissioner)];
        for (const z of commissioner) {
          splitTables.push(tempTable.filter((row) => row.commissioner === z));
        }
      }
      const mapTemp = {};
      const filteredDevicesMap = [];
      for (let a = 0; a < splitTables.length; a++) {
        mapTemp[a] = false;
        filteredDevicesMap.push([]);

        for (let b = 0; b < splitTables[a].length; b++) {
          splitTables[a][b].index = b;
          splitTables[a][b].cabinetSelected = false;
          splitTables[a][b].commissionerSelected = false;
          splitTables[a][b].connectedSelected = false;
          splitTables[a][b].disconnectedSelected = false;
          splitTables[a][b].installedSelected = false;
        }
      }
      this.mapOpen = mapTemp;
      this.filterDevicesList = filteredDevicesMap;
      this.cabinetPerTableIndex = filteredDevicesMap.flat(1);
      this.viewedTableData = [];
    
      splitTables.forEach((cabinet_data, index) => {
        this.viewedTableData[index] = {cabinet_id: splitTables[index][0].cabinet, data: cabinet_data};
      });
      const exportedTable = JSON.parse(JSON.stringify(tempTable));
      const disconnectedDevices = [];
      const allDevices = [];
      for (const r of exportedTable) {
        for (const t of r.disconnected) {
          const metaDevice = t['meta.device'];
          const device = {
            'cabinet': metaDevice.cabinet_id || 'N/A',
            'pole number': metaDevice.pole_number || 'N/A',
            'pole height': metaDevice.pole_height || 'N/A',
            'circuit number': metaDevice.circuit_number || 'N/A',
            'device_id': t.id,
            'location': t['meta.location'].lat + ', ' + t['meta.location'].lng,
            'installer': r.commissioner,
            'date':
              typeof t.date === 'number'
                ? new Date(r.date).toLocaleDateString()
                : 'N/A'
          };
          disconnectedDevices.push(device);
        }
        for (const t of r.installed) {
          const metaDevice = t['meta.device'];
          const device = {
            'cabinet': metaDevice.cabinet_id || 'N/A',
            'pole number': metaDevice.pole_number || 'N/A',
            'pole height': metaDevice.pole_height || 'N/A',
            'circuit number': metaDevice.circuit_number || 'N/A',
            'device_id': t.id,
            'location': t['meta.location'].lat + ', ' + t['meta.location'].lng,
            'installer': r.commissioner,
            'status': t['sys___active'] === -3 ? 'disconnected' : 'connected',
            'date':
              typeof t.date === 'number'
                ? new Date(r.date).toLocaleDateString()
                : 'N/A'
          };
          allDevices.push(device);
        }
      }
      this.exportAllData = allDevices;
      this.exportData = disconnectedDevices;
      this.reportOpen = true;
      this.datesChange = false;
      this.commissionersChange = false;
      this.cabinetsChange = false;
    },
    closeReport() {
      this.reportOpen = false;
    },
    selectCabinet(cabinet) {
      if (this.queryCabinets.indexOf(cabinet) > -1) {
        this.queryCabinets.splice(this.queryCabinets.indexOf(cabinet), 1);
      } else {
        this.queryCabinets.push(cabinet);
      }
    },
    selectCommissioner(commissioner) {
      if (this.queryCommissioners.indexOf(commissioner) > -1) {
        this.queryCommissioners.splice(
          this.queryCommissioners.indexOf(commissioner),
          1
        );
      } else {
        this.queryCommissioners.push(commissioner);
      }
    },
    setStartDate(start) {
      const projectDates = this.$store.state.Projects.projects_metadata.get(this.$store.state.User.project.id).commissionedByDate;
      
      if (!start || start === this.end_date) {
        this.projectEndTime = new Date().getTime();
        if (this.tab !== 'dates') {
          this.projectStartTime = projectDates[0];
          const date = new Date(projectDates[0]).toISOString().substring(0, 10);
          this.start_date = date;
        } else {
          this.projectStartTime = this.projectEndTime - 7 * 86400000;
          const date = new Date(this.projectStartTime)
            .toISOString()
            .substring(0, 10);
          this.start_date = date;
        }
      } else {
        this.start_date = start;
      }
    },
    setYesterday() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(newTime).getDate()).padStart(2, 0)
      );
    },
    setLastWeek() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000 * 6;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(newTime).getDate()).padStart(2, 0)
      );
    },
    setLastMonth() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000 * 30;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(now).getDate()).padStart(2, 0)
      );
    },
    setLastYear() {
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      const now = new Date(this.end_date.split('-')).getTime();
      const newTime = now - 86400000 * 365;
      this.setStartDate(
        String(new Date(newTime).getFullYear()) +
          '-' +
          String(new Date(newTime).getMonth() + 1).padStart(2, 0) +
          '-' +
          String(new Date(newTime).getDate()).padStart(2, 0)
      );
    },
    setEndDate(end) {
      this.end_date = end;
    },
    resetChange(x) {
      this.changeIndex = x;
    },
    setDefaultDates() {
      this.set_default = true;
      this.setStartDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
      this.set_default = false;
    },
    getDevices() {
      const tempDevices = this.devices.filter((device) => Utils.hasTondoClass(device.class_name));
      for (const i of tempDevices) {
        try {
          if (!i['meta.device'].cabinet_id) {
            i['meta.device'].cabinet_id = 'unspecified';
          }
          if (!i['meta.commission'].hasOwnProperty('commissioner')) {
            i['meta.commission'].commissioner = 'unspecified';
          }
          i.date = this.getDeviceTime(i);
        } catch (e) {
          console.log(i);
        }
      }
      const devices = tempDevices.filter((device) => device['meta.commission'].commissioned === true);
      this.allDevices = devices;
      const commissionersList = [];
      for (const k of devices) {
        if (k['meta.commission'].hasOwnProperty('commissioner') && k['meta.commission']['commissioner'] && k['meta.commission']['commissioner'].length > 0) {
          commissionersList.push(
            k['meta.commission'].commissioner === 'none'
              ? 'unspecified'
              : k['meta.commission'].commissioner.split('@')[0].trim()
          );
        } else {}
      }
      this.commissionersList = [...new Set(commissionersList)];
      this.queryCommissioners = [];
      const datesortedDevices = devices
        .filter((dev) => dev.date !== 'unspecified')
        .sort((a, b) =>
          a.date > b.date &&
          a.date !== 'unspecified' &&
          b.date !== 'unspecified'
            ? 1
            : a.date === 'unspecified'
            ? 1
            : -1
        );
      const dateSplitObject = {};
      for (const device of datesortedDevices) {
        if (dateSplitObject.hasOwnProperty(device.date)) {
          dateSplitObject[`${device.date}`].devices.push(device);
        } else {
          dateSplitObject[`${device.date}`] = {};
          dateSplitObject[`${device.date}`].devices = [device];
        }
      }
      for (const date in dateSplitObject) {
        for (const a of dateSplitObject[date].devices) {
          try {
            const cabinetID = a['meta.device'].cabinet_id;
            const commissioner = a['meta.commission'].commissioner.split('@')[0].trim();
            const device = a;
            if (!dateSplitObject[date].hasOwnProperty(cabinetID)) {
              dateSplitObject[date][`${cabinetID}`] = {};
            }
            if (!dateSplitObject[date][`${cabinetID}`].hasOwnProperty(`${commissioner}`)) {
              dateSplitObject[date][`${cabinetID}`][`${commissioner}`] = [device];
            } else {
              dateSplitObject[date][`${cabinetID}`][`${commissioner}`].push(device);
            }
          } catch (e) {
            console.log(e);
          }
        }
        delete dateSplitObject[date].devices;
      }
      const tableData = [];
      for (const date in dateSplitObject) {
        if (date !== 'undefined') {
          for (const cabinet in dateSplitObject[date]) {
            for (const commissioner in dateSplitObject[date][cabinet]) {
              const data = dateSplitObject[date][cabinet][commissioner];
              const disconnected = dateSplitObject[date][cabinet][
                commissioner
              ].filter((dev) => dev.sys___active === -3);
              const connected = dateSplitObject[date][cabinet][
                commissioner
              ].filter((dev) => dev.sys___active !== -3);
              tableData.push({
                date: parseInt(date, 10),
                cabinet,
                cabinetSelected: false,
                commissioner,
                commissionerSelected: false,
                installed: data,
                installedSelected: false,
                connected,
                connectedSelected: false,
                disconnected,
                disconnectedSelected: false,
                index: null
              });
            }
          }
        }
      }
      if (dateSplitObject.hasOwnProperty('undefined')) {
        delete dateSplitObject['undefined'];
      }
      for (let m = 0; m < tableData.length; m++) {
        tableData[m].index = m;
      }
      this.tableData = tableData;
    },
    getDeviceTime(device) {
      let month;
      let year;
      let time;
      if (
        device['meta.commission'].date.indexOf('.') !== -1 &&
        device['meta.commission'].date !== 'none'
      ) {
        if (device['meta.commission'].date.substring(3, 5) === '01') {
          month = 0;
          year = parseInt(device['meta.commission'].date.substring(6, 10), 10);
        } else {
          month =
            parseInt(device['meta.commission'].date.substring(3, 5), 10) - 1;
        }
        time = new Date(
          year
            ? year.toString()
            : device['meta.commission'].date.substring(6, 10),
          month,
          device['meta.commission'].date.substring(0, 2)
        ).getTime();
        if (isNaN(time)) {
          if (device['meta.commission'].date.substring(5, 7) === '01') {
            month = 0;
            year = parseInt(device['meta.commission'].date.substring(0, 4), 10);
          } else {
            month =
              parseInt(device['meta.commission'].date.substring(5, 7), 10) - 1;
          }
          return new Date(
            year
              ? year.toString()
              : device['meta.commission'].date.substring(0, 4),
            month,
            device['meta.commission'].date.substring(8, 10)
          ).getTime();
        } else return time;
      } else if (device['meta.commission'].date !== 'none') {
        if (device['meta.commission'].date.substring(0, 2) === '01') {
          month = 0;
          year = parseInt(device['meta.commission'].date.substring(6, 10), 10);
        } else {
          month =
            parseInt(device['meta.commission'].date.substring(0, 2), 10) - 1;
        }
        return new Date(
          year
            ? year.toString()
            : device['meta.commission'].date.substring(6, 10),
          month,
          device['meta.commission'].date.substring(3, 5)
        ).getTime();
      }
    },
    setTableActiveDevices(index, table) {
      let deviceArr = [];
      const tempViewed = JSON.parse(JSON.stringify(table));
      for (const m of tempViewed) {
        if (m.commissionerSelected || m.installedSelected) {
          deviceArr = [...deviceArr, ...m.installed.map((device) => device.id)];
        } else if (m.connectedSelected) {
          deviceArr = [...deviceArr, ...m.connected.map((device) => device.id)];
        } else if (m.disconnectedSelected) {
          deviceArr = [...deviceArr, ...m.disconnected.map((device) => device.id)];
        }
        if (m.cabinetSelected && this.cabinetsFullData.find((cab) => cab['meta.device'].cabinet_id === m.cabinet)) {
          deviceArr = [
            ...deviceArr,
            this.cabinetsFullData.find((cab) => cab['meta.device'].cabinet_id === m.cabinet).id,
            ...m.installed.map((device) => device.id)
          ];
        }
      }
      deviceArr = [...new Set(deviceArr)];
      if (deviceArr.length === 0) {
        Vue.set(this.mapOpen, index, false);
      } else {
        Vue.set(this.mapOpen, index, true);
      }
      Vue.set(this.filterDevicesList, index, deviceArr);
      this.viewedTableData[index].data = tempViewed;
      this.changeIndex = index;
    },
    selectMapCommissioner(index, commissioner) {
      const tempViewed = JSON.parse(
        JSON.stringify(this.viewedTableData[index].data)
      );
      for (const i of tempViewed) {
        if (i.commissioner === commissioner) {
          i.commissionerSelected = !i.commissionerSelected;
        }
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    selectMapCabinet(index, cabinet, table) {
      const tempViewed = JSON.parse(JSON.stringify(table));
      for (const i of tempViewed) {
        if (i.cabinet === cabinet) {
          i.cabinetSelected = !i.cabinetSelected;
        }
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    selectMapDevices(index, row, type) {
      const tempViewed = JSON.parse(
        JSON.stringify(this.viewedTableData[index].data)
      );
      if (type === 'installed') {
        tempViewed[row].installedSelected = !tempViewed[row].installedSelected;
      } else if (type === 'connected') {
        tempViewed[row].connectedSelected = !tempViewed[row].connectedSelected;
      } else {
        tempViewed[row].disconnectedSelected =
          !tempViewed[row].disconnectedSelected;
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    selectCol(index, col, open = null) {
      const tempViewed = JSON.parse(
        JSON.stringify(this.viewedTableData[index].data)
      );
      let boolCheck = false;
      if (col === 'Installed Devices') {
        if (open === null) {
          for (const i of tempViewed) {
            if (!i.installedSelected) {
              boolCheck = true;
              break;
            }
          }
          for (const j of tempViewed) {
            j.installedSelected = boolCheck;
          }
        } else if (open) {
          for (const j of tempViewed) {
            j.installedSelected = true;
          }
        } else {
          for (const j of tempViewed) {
            j.installedSelected = false;
          }
        }
      }
      if (col === 'Installation Success') {
        for (const i of tempViewed) {
          if (!i.connectedSelected) {
            boolCheck = true;
            break;
          }
        }
        for (const j of tempViewed) {
          j.connectedSelected = boolCheck;
        }
      }
      if (col === 'Installation Fail') {
        for (const i of tempViewed) {
          if (!i.disconnectedSelected) {
            boolCheck = true;
            break;
          }
        }
        for (const j of tempViewed) {
          j.disconnectedSelected = boolCheck;
        }
      }
      if (col === 'Installer') {
        for (const i of tempViewed) {
          if (!i.commissionerSelected) {
            boolCheck = true;
            break;
          }
        }
        for (const j of tempViewed) {
          j.commissionerSelected = boolCheck;
        }
      }
      this.setTableActiveDevices(index, tempViewed);
    },
    getChangedTableData(index) {
      return this.tableData[index];
    },
    print() {
      this.filterDevicesList.fill([]);
      for (const key in this.mapOpen) {
        Vue.set(this.mapOpen, key, false);
      }
      for (const table of this.viewedTableData) {
        for (const row of table.data) {
          row.cabinetSelected = false;
          row.commissionerSelected = false;
          row.connectedSelected = false;
          row.disconnectedSelected = false;
          row.installedSelected = false;
        }
      }
      setTimeout(() => {
        window.print();
      }, 500);
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Date', value: 'date', align: 'center', sortable: false },
        {
          text: 'Installer',
          value: 'commissioner',
          align: 'center',
          sortable: false
        },
        { text: 'Cabinet', value: 'cabinet', align: 'center', sortable: false },
        {
          text: 'Installed Devices',
          value: 'installed',
          align: 'center',
          sortable: false
        },
        {
          text: 'Installation Success',
          value: 'connected',
          align: 'center',
          sortable: false
        },
        {
          text: 'Installation Fail',
          value: 'disconnected',
          align: 'center',
          sortable: false
        }
      ];
    },
    projectTimezone(){
      return this.$store.state.Global.timezone;
    },
    sortList() {
      const list = [];
      if (this.cabinetsList.length) {
        list.push({text: this.$t('cabinet'), value: 'cabinet'});
      }
      if (this.commissionersList.length) {
        list.push({text: this.$t('installer'), value: 'installer'});
      }
      return list;
    },
    lang() {
      return this.$store.state.Global.lang;
    },
    mapIndexChange() {
      return this.mapOpen;
    },
    devicesCount() {
      return this.$store.state.Reports.devicesCount;
    },
    devices() {
      return this.$store.state.Reports.reportsList;
    }
  },
  watch: {
    period(newVal) {
      this.setStartDate();
    },
    devices() {
      this.generatePageData();
    },
    devicesCount() {
      this.generatePageData();
    },
    mapIndexChange(newVal) {
      this.mapOpen = newVal;
    },
    tab(newVal) {
      this.validateForm();

      if (newVal === 'dates') {
        this.period = 'week';
      } else if (newVal === 'commissioner' || newVal === 'cabinet') {
        this.period = 'all';
      }
    }
  }
};
</script>

<style scoped>
.tabs {
  width: 100%;
  max-height: 45px;
  background-color: #f2f4f8;
}
.tabs button {
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Open Sans', 'Arial', monospace !important;
  padding: 5px;
  font-weight: 400;
}

.tabs .button-active {
  border: 0.5px solid #d8e3f3;
  border-radius: 10px;
  color: #7e57c2;
  font-weight: 600;
  background-color: #f2f4f8;
}
</style>

<style lang="scss">
.monitoring-report-data div {
  padding: 1px !important;
}
.selectionBtn {
  background-color: #7e9edf;
  padding: 4px 8px;
  border-radius: 5px;
}
.selectErr {
  color: red;
}
.noDataAvail {
  font-weight: bold;
  font-size: 24px;
}
.cardHeaderMonitoring {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.selectHeaderMonitoring {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.generate-btn-style {
  .theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
    background-color: grey !important;
    color: black !important;
    border-color: rgb(129, 46, 129);
  }
}

.errorTextBold {
  font-size: 22px;
  font-weight: bold;
}
.custom__tag {
  font-size: 18px;
  font-weight: bold;
}

@keyframes a {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
@media print {
  @page {
    size: landscape;
    margin: 0;
  }
  * {
    overflow: visible !important;
  }
  .v-navigation-drawer,
  .main-toolbar,
  .btnsHide {
    visibility: hidden;
    display: none;
  }
  .ml-4,
  .mr-4,
  .mt-3 {
    margin: 0;
  }
  .piecharten {
    width: 200px;
    margin-right: 100px;
  }
  .piecharthe {
    width: 200px;
    margin-left: 60px;
  }
  .printElement {
    visibility: visible;
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
  }
  .v-card {
    border: none !important;
  }
}

.autocomplete-style {
  .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
    min-height: fit-content !important;
  }

  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin-bottom: 0px !important;
  }

  .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot > input {
    margin-top: 0px;
  }

  .v-select.v-text-field--enclosed:not(.v-text-field--single-line) .v-select__selections {
    padding-top: 0px !important;
  }

  .v-text-field.v-text-field--enclosed .v-input__prepend-outer, .v-text-field.v-text-field--enclosed .v-input__prepend-inner, .v-text-field.v-text-field--enclosed .v-input__append-inner, .v-text-field.v-text-field--enclosed .v-input__append-outer {
    margin: 5px 0 !important;
  }

  .v-text-field--box > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outline > .v-input__control > .v-input__slot {
    min-height: 30px !important;
  }

  .v-list__tile {
    height: 30px !important;
  }

  .v-list__tile__content {
    font-size: 13px !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    padding: 0 5px !important;
  }
}
</style>
