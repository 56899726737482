export interface Curve {
  id: string;
  name: string;
  description: string;
  start: {
    type: string;
    offset: any;
    duration: string;
    power: any;
  };
  end: {
    type: string;
    offset: any;
    duration: string;
    power: any;
  };
  color: string;
  datasets: number[];
  labels: string[];
  timezone: string;
}

export interface TableHeaders {
  value: string;
  text: string;
  sortable?: boolean;
}

export interface Route {
  createFilter?: boolean;
  realPath?: string;
  path?: string;
  name?: string;
  menuTitle?: string;
  role?: string[];
  menuIcon?: string;
  menuImg?: string;
  menuSelectedImg?: string;
  module?: string | false;
  component?: any;
  redirect?: string;
  meta?: {
    title?: string;
    auth?: boolean;
    company?: boolean;
    sidebar?: boolean;
    footer?: boolean;
    header?:
      | boolean
      | {
          component?: {
            side: 'left' | 'right';
            el;
          };
        };
    container?: {
      fit?: boolean;
      flex?: boolean;
    };
  };
  children?: Route[];
}

export enum Type {
  DEVICE = 'device',
  HUB = 'hub'
}

export enum FloodSensor {
  BOTTOM_FLOAT_TRIGGER = 'lsi1',
  TOP_FLOAT_TRIGGER = 'lsi2',
  ULRASONIC_SENSOR_TRIGGER = 'water_height_trigger'
}

export interface Prop {
  objectType: Types;
  objectID: string;
  id: string;
  name: string;
  value: any;
  version: string;
  signature: string;
  zorder: number;
  classname: string;
}

export enum Types {
  PROPS = 'properties',
  DEVICES = 'devices',
  COMPANIES = 'companies',
  PROJECTS = 'projects'
}

export interface AlertsMap {
  [key: string]: number;
}

export type TariffDataProp = Record<string, TariffData[]>;

export interface TariffData {
  months: number[];
  peak: number;
  high: number;
  low: number;
  class: string;
};

export type Tariff = Record<string, TariffData[]>;

export type TariffTableRow = TariffData & {
  year: string;
  period: string;
  show_year?: boolean; // Optional, since it’s not always present
};
