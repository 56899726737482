<template>
  <v-layout row wrap style="direction: ltr">
    <div id="chartdiv" ref="chartdiv" style="width: 100%; height: 300px"></div>
  </v-layout>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
am4core.addLicense('CH351911686');
import * as am4charts from '@amcharts/amcharts4/charts';



export default {
  name: 'GaugeChart',
  props: ['chartData', 'selectedTabs', 'tabs_colors'],
  data() {
    return {
      chart: null,
      axis: null
    };
  },
  mounted() {
    this.disposeChart();
    this.setGraph();
  },
  beforeDestroy() {
    this.disposeChart();
  },
  methods: {
    disposeChart() {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    },
    setGraph() {
      const chart = am4core.create('chartdiv', am4charts.GaugeChart);

      // Set the chart background to white
      chart.background.fill = am4core.color('#FFFFFF');
      chart.background.fillOpacity = 1; // Make sure it's fully opaque

      chart.startAngle = 0;
      chart.endAngle = 360;

      // Configure the main axis
      this.axis = chart.xAxes.push(new am4charts.ValueAxis());
      this.axis.min = 0;
      this.axis.max = 360;
      this.axis.strictMinMax = true;

      // Make the axis start from 0° on the right, increasing counterclockwise
      this.axis.renderer.useChartAngles = false;
      this.axis.renderer.startAngle = 360;   // Starts at 0° on the right side
      this.axis.renderer.endAngle = 0;   // Ends at 360° going counterclockwise

      // Set circle (axis) pattern color
      this.axis.renderer.line.stroke = am4core.color('#dbe0e8'); // Circle outline color
      this.axis.renderer.grid.template.stroke = am4core.color('#dbe0e8'); // Grid line color
      this.axis.renderer.grid.template.strokeOpacity = 0.5; // Adjust grid line opacity

      // Display grid lines and labels every 20 degrees
      this.axis.renderer.minGridDistance = 20;
      this.axis.renderer.ticks.template.disabled = true;
      this.axis.renderer.labels.template.adapter.add('text', (label, target) => {
        const value = target.dataItem.value;
        return value === 360 ? '' : Math.round(value).toString();
      });

      // Customize axis visuals
      this.axis.renderer.line.strokeOpacity = 1;
      this.axis.renderer.line.strokeWidth = 1;
      this.axis.renderer.inside = false;
      this.axis.renderer.labels.template.radius = 10; // Adjust radius for readability
      this.axis.renderer.labels.template.fill = am4core.color('#6a6a6a');
      this.axis.renderer.rtl = false;

      this.defineChartSlices();

      // const magnitudes = this.selectedTabs.map((tab) => this.chartData[`Phasor_${tab}_MGT`]);
      // const max_magnitude = Math.max(...magnitudes) * 1.1;

      this.selectedTabs.forEach((tab) => {
        const angle = this.chartData[`Phasor_${tab}_Angle`];
        const hand = chart.hands.push(new am4charts.ClockHand());
        hand.radius = am4core.percent((this.chartData[`Phasor_${tab}_MGT`] / 360) * 100);
        hand.zIndex = 1;
        hand.startWidth = 3;
        hand.endWidth = 3;
        hand.fill = am4core.color(this.tabs_colors[tab]);

        // Remove the black stroke
        hand.strokeWidth = 0;       // Removes the stroke width
        hand.strokeOpacity = 0;     // Makes sure there's no stroke opacity
        hand.stroke = hand.fill;    // Optionally set stroke to the same color as fill

        hand.showValue(angle >= 0 ? angle : angle + 360);
      });

      this.chart = chart;
    },
    defineChartSlices(){
      for (let start = 0; start < 360; start += 20) {
        this.createSlice(start, start + 20);
      }
    },
    createSlice(startValue, endValue) {
      const range = this.axis.axisRanges.create();
      range.value = startValue;
      range.endValue = endValue;
      range.axisFill.fill = am4core.color('#FFFFFF'); // Set each slice background to white
      range.axisFill.fillOpacity = 0; // Ensure no color overlay
      range.axisFill.zIndex = -1; // Position behind the hand
      range.axisFill.disabled = false;
    }
  },
  watch: {
    chartData(newVal) {
      this.setGraph();
    },
    selectedTabs(newVal) {
      this.setGraph();
    }
  }
};
</script>
