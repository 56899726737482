













































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Props, User, Global, Reports, MapSettings } from '@/store';
import { cloneDeep } from 'lodash';
import { MONTHS } from '@/modules/constants';
import { Tariff } from '@/modules/Types';
import moment from 'moment';

@Component({
    components: {}
})

export default class EditTariffDialog extends Vue {

    @Global.State('lang') lang;

    @Prop() tariff_data: Array<Tariff & {year: string}>;
    @Prop() mode: string;
    @Prop() unavailable_years: string[];

    year_tariff_data = [];
    selected_year = '';
    years_list = [];
    year_search = '';
    month_search = '';
    selected_months = [];
    available_months = [];
    dialog = true;
    months = MONTHS;
    tariff_types_list = [
        {text: 'Tariff 1 (Peak)', field_value: 'peak'},
        {text: 'Tariff 2 (High)', field_value: 'high'},
        {text: 'Tariff 3 (Low)', field_value: 'low'}
    ];
    tariff_list_values = [null, null, null];
    editable_items = [];

    mounted() {
        const all_months = Object.keys(MONTHS).map((month) => +month);
        if (this.mode === 'edit') {
            this.year_tariff_data = cloneDeep(this.tariff_data).map((tariff) => ({...tariff, editable: false}));
            const unavailable_months = this.year_tariff_data.map((data) => data.months).flat();
            this.available_months = all_months
                .filter((month_num) => !unavailable_months.includes(month_num))
                .map((month_num) => ({text: this.$t(MONTHS[month_num]), value: month_num}));
        }else {
            this.available_months = all_months.map((month_num) => ({text: this.$t(MONTHS[month_num]), value: month_num}));
            this.years_list = this.generateYearsList().filter((year) => !this.unavailable_years.includes(year.toString()));
        }
    }

    get available_month_nums() {
        return this.available_months.map((month) => month.value);
    }
    
    validateMinValue(index: number){
        if (this.tariff_list_values[index] && +this.tariff_list_values[index] < 0) {
            this.tariff_list_values[index] = Math.abs(+this.tariff_list_values[index]);
        }
    }

    removeDefinedMonth(month_num, tariff_index) {
        this.year_tariff_data[tariff_index].months = this.year_tariff_data[tariff_index].months.filter((month) => month !== month_num);

        this.available_months.push({text: this.$t(MONTHS[month_num]), value: month_num});
    }

    removeAvailableMonth(month_num, tariff_index) {
        this.available_months = this.available_months.filter((month) => month.value !== month_num);
        this.year_tariff_data[tariff_index].months.push(month_num);
    }

    generateYearsList(){
        const currentYear = moment().year();
        return Array.from({ length: 6 }, (_, i) => currentYear - 4 + i);
    }

    updateSelectedMonths(selected_month) {
        if (this.selected_months.includes(selected_month)) {
            this.selected_months = this.selected_months.filter((month_num) => month_num !== selected_month);
        }else {
            this.selected_months.push(selected_month);
        }
    }

}
