












































































































import Vue from 'vue';
import Component from 'vue-class-component';
import countries from 'i18n-iso-countries';
import { parsePhoneNumberFromString, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, Props } from '@/store';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/he.json'));

@Component({})
export default class CountryPhoneInput extends Vue {

    @Global.State('lang') lang;
    @Props.State('project_default_location') project_default_location;

    @Prop() phone_number;

    phoneNumber = ""; // User-entered phone number
    selectedCountry = null; // Selected country code
    countries = []; // List of all countries
    filteredCountries = []; // Filtered countries for dropdown
    countrySearch = '';

    async mounted() {
        // Load country data and preload a phone number if available
        this.loadCountries();
        if (this.phone_number) {
            this.preloadPhoneNumber(this.phone_number);
        }else {
            await this.setCountryByDefaultLocation();
        }
    }

    @Watch('project_default_location')
    async setCountryByDefaultLocation(){
        try {
            if (this.project_default_location) {
                // Use a reverse geocoding API to get country information
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.project_default_location.lat}&lon=${this.project_default_location.lng}`
                );
                const data = await response.json();
                const country_code = data.address && data.address.country_code && data.address.country_code.toUpperCase();
                this.selectedCountry = country_code;
            }
        }catch(e) {}
        if (!this.selectedCountry || this.selectedCountry === 'PS') {
            this.selectedCountry = 'IL';
        }
    }

    loadCountries() {
      // Load all countries with names and dial codes
      this.countries = getCountries().map((code) => {
        return {
          code,
          calling_code: getCountryCallingCode(code),
          country_name: this.getFullCountryName(code)
        };
      }).sort((c1, c2) => c1.country_name.localeCompare(c2.country_name))
      .filter((country) => country.code !== 'PS');
      this.filteredCountries = this.countries; // Initialize with all countries
    }

    getFlagImageUrl(countryCode) {
        return `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`; // w40 for 40px wide images
    }

    getFullCountryName(countryCode) {
        return countries.getName(countryCode, this.lang) || this.$t('Unknown country');
    }

    validateCountry() {
        if (!this.selectedCountry) {
            return this.$t('Required field');
        }

        return true;
    }

    customFilter(item, queryText) {
      const search = queryText.toLowerCase();

      // Match against multiple fields
      const name = (item.country_name || '').toLowerCase();
      const code = (item.code || '').toLowerCase();
      const callingCode = (item.calling_code || '').toLowerCase();

      // Return true if any field matches the search term
      return (
        name.includes(search) ||
        code.includes(search) ||
        `+${callingCode}`.includes(search) || // Match with "+" prefix
        callingCode.includes(search) // Match without "+" prefix
      );
    }

    @Watch('countrySearch')
    filterCountries() {
        const searchTerm = this.countrySearch.toLowerCase();
        // Filter the countries based on the search term
        this.filteredCountries = this.countries.filter((country) =>
            this.customFilter(country, searchTerm)
        );
    }

    validatePhoneNumber() {
        // Validate the phone number for the selected country
        if (!this.selectedCountry) {
            return true;
        }

        if (!this.phoneNumber) {
            return this.$t('Required field');
        }

        const parsedNumber = parsePhoneNumberFromString(this.phoneNumber, this.selectedCountry);

        return parsedNumber && parsedNumber.isValid()
            ? true
            : this.$t('Invalid phone number');
    }

    @Watch('selectedCountry')
    @Watch('phoneNumber')
    updatePhoneNumber() {
        const selected = this.countries.find((country) => country.code === this.selectedCountry);

        this.$emit('phoneNumberChanged', `+${selected.calling_code}${this.phoneNumber}`);
    }

    preloadPhoneNumber(phone) {
      // Preload phone number and determine its country
      const parsedNumber = parsePhoneNumberFromString(phone);
      if (parsedNumber) {
        this.phoneNumber = parsedNumber.nationalNumber;
        this.selectedCountry = parsedNumber.country === 'PS' && 'IL' || parsedNumber.country;
      } else {
        this.phoneNumber = phone;
        this.setCountryByDefaultLocation();
      }

      if (!parsedNumber || !parsedNumber.country) {
        this.setCountryByDefaultLocation();
      }
    }
}
