











































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';
import { cloneDeep } from 'lodash';
import { MONTHS } from '@/modules/constants';
import moment from 'moment';
import { TariffTableRow } from '@/modules/Types';

@Component({
    components: {}
})

export default class ConfigTouTab extends Vue {

    @Global.State('lang') lang;

    @Prop() tou_tariff_prop;
    @Prop() selected_tou: TariffTableRow & {index: number};
    @Prop() selected_tab: string;

    selected_year = '';
    years_list = [];
    year_search = '';
    selected_months = [];
    months = MONTHS;
    show_months_error = false;
    months_error_text = '';
    tariff_types_list = [
        {text: 'Tariff 1 (Peak)', field_name: 'peak', value: null},
        {text: 'Tariff 2 (High)', field_name: 'high', value: null},
        {text: 'Tariff 3 (Low)', field_name: 'low', value: null}
    ];
    tou_data = null;

    mounted() {
        this.setDefaultValues();
        if (this.selected_tou) {
            this.updatedSelectedTouData();
        }
    }

    setDefaultValues(){
        this.tou_data = cloneDeep(this.tou_tariff_prop);
        this.years_list = this.generateYearsList();
        this.selected_year = moment().year().toString();
        this.selected_months = [];
        this.year_search = '';
        this.show_months_error = false;
        this.tariff_types_list.forEach((type) => type.value = null);
    }

    updatedSelectedTouData(){
        this.selected_year = this.selected_tou.year;
        this.selected_months = cloneDeep(this.selected_tou.months);
        this.tariff_types_list.forEach((type) => type.value = this.selected_tou[type.field_name]);
    }

    get months_list() {
        return Object.keys(MONTHS).map((month) => +month);
    }

    discardChanges() {
        this.setDefaultValues();
        this.$emit('setSelectedTab', 'history');
    }

    @Watch('selected_months')
    @Watch('selected_year')
    updateMessageStatus(){
        this.show_months_error = !this.selected_months.length;
        this.months_error_text = 'Choose at least 1 month';
        if (this.show_months_error) {
            return;
        }
        this.months_error_text ='';

        let unavailable_months = [];

        if (this.selected_tou){
            unavailable_months = this.tou_data[this.selected_year]
                .reduce((acc, curr) => acc.concat(curr.months), [])
                .filter((month) => !this.selected_tou.months.includes(month));
        }else if (this.tou_data[this.selected_year]) {
            unavailable_months = this.tou_data[this.selected_year].reduce((acc, curr) => acc.concat(curr.months), []);
        }
        
        if (this.tou_tariff_prop[this.selected_year]) {
            const overlap = this.selected_months.filter((month) => unavailable_months.includes(month));
            if (overlap.length) {
                this.show_months_error = true;

                if (overlap.length) {
                    const overlap_months_text = overlap.map((month) => this.$t(MONTHS[month])).join(', ');
                    this.months_error_text = this.lang === 'en' 
                        ? `${overlap_months_text} already defined. Saving changes will update the TOU tariff to the current value`
                        : `${this.$t('TOU tariff for')} ${overlap_months_text} ${this.$t('is already defined')}. ${this.$t('Saving changes will update the TOU tariff to the current value')}`;
                }
            }
        }
    }

    generateYearsList(){
        const currentYear = moment().year();
        return Array.from({ length: 6 }, (_, i) => (currentYear - 4 + i).toString()).reverse();
    }

    validateMinValue(index: number){
        if (+this.tariff_types_list[index].value < 0) {
            this.tariff_types_list[index].value = Math.abs(+this.tariff_types_list[index].value);
        }
    }

    updateSelectedMonths(selected_month) {
        if (this.selected_months.includes(selected_month)) {
            this.selected_months = this.selected_months.filter((month_num) => month_num !== selected_month);
        }else {
            this.selected_months.push(selected_month);
        }
    }

    updateTouProp() {
        this.show_months_error = !this.selected_months.length;
        this.months_error_text = 'Choose at least 1 month';

        if (this.$refs.form && !(this.$refs.form as Vue & { validate: () => boolean }).validate() || !this.$refs.form || this.show_months_error){
            return;
        }
        
        const tou_values = {months: cloneDeep(this.selected_months).sort((m1, m2) => m1 - m2)};
        this.tariff_types_list.forEach((tou) => tou_values[tou.field_name] = tou.value);

        if (this.tou_data[this.selected_year]) {
            const same_tou_index = this.tou_data[this.selected_year]
                .findIndex((tou) => +tou.peak === tou_values['peak'] && +tou.high === tou_values['high'] && +tou.low === tou_values['low']);
            let item_index = -1;
            if (same_tou_index >= 0) {
                item_index = same_tou_index;
                this.tou_data[this.selected_year][same_tou_index].months = this.selected_tou && this.selected_tou.index === item_index
                    ? [...tou_values.months]
                    : [...new Set([...this.tou_data[this.selected_year][same_tou_index].months, ...tou_values.months])];
                this.tou_data[this.selected_year][same_tou_index].months.sort((m1, m2) => m1 - m2);
            }else {
                this.tou_data[this.selected_year].push({...tou_values});
                item_index = this.tou_data[this.selected_year].length - 1;
            }
            const empty_months_index = [];
            this.tou_data[this.selected_year].forEach((tou, index) => {
                if (index !== item_index) {
                    tou.months = tou.months.filter((month_num) => !this.tou_data[this.selected_year][item_index].months.includes(month_num));
                    if (!tou.months.length) {
                        empty_months_index.push(index);
                    }
                }
            });
            this.tou_data[this.selected_year] = this.tou_data[this.selected_year].filter((tou, index) => !empty_months_index.includes(index));
        }else {
            this.tou_data[this.selected_year] = [{...tou_values}];
        }

        this.$emit('updateTouProp', this.tou_data);
    }

    beforeDestroy() {
        if (this.selected_tou) {
            this.$emit('editSelectedTOU', null);
        }
    }
}
