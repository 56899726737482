var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"max-width":"90% !important"},attrs:{"row":"","wrap":""}},[(_vm.lang === 'en')?[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.filteredCountries,"label":_vm.$t('Area code'),"item-text":"country_name","item-value":"code","required":"","dense":"","rules":[_vm.validateCountry],"no-data-text":_vm.$t('No data available'),"search-input":_vm.countrySearch,"filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.countrySearch=$event},"update:search-input":function($event){_vm.countrySearch=$event},"change":function () { return _vm.countrySearch = ''; }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"padding-right":"5px"},attrs:{"src":_vm.getFlagImageUrl(item.code),"alt":"","width":"30"}}),_c('span',{staticStyle:{"margin-left":"4px","color":"black"}},[_vm._v("(+"+_vm._s(item.calling_code)+")")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"padding-right":"5px"},attrs:{"src":_vm.getFlagImageUrl(item.code),"alt":"","width":"20"}}),_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(item.country_name))]),_c('span',{staticStyle:{"margin-left":"4px","color":"black"}},[_vm._v("(+"+_vm._s(item.calling_code)+")")])])]}}],null,false,2667654909),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-flex',{attrs:{"xs1":""}})]:_vm._e(),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"disabled":!_vm.selectedCountry,"label":_vm.$t('Phone number'),"type":"tel","autocomplete":"tel","outlined":"","dense":"","required":"","rules":[_vm.validatePhoneNumber]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),(_vm.lang === 'he')?[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.filteredCountries,"label":_vm.$t('Area code'),"item-text":"country_name","item-value":"code","dense":"","required":"","rules":[_vm.validateCountry],"no-data-text":_vm.$t('No data available'),"search-input":_vm.countrySearch,"filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.countrySearch=$event},"update:search-input":function($event){_vm.countrySearch=$event},"change":function () { return _vm.countrySearch = ''; }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{attrs:{"src":_vm.getFlagImageUrl(item.code),"alt":"","width":"30"}}),_c('span',{staticStyle:{"margin-right":"4px","color":"black"}},[_vm._v("("+_vm._s(item.calling_code)+"+)")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{style:(_vm.lang === 'en' ? 'padding-right: 5px' : 'padding-left: 5px'),attrs:{"src":_vm.getFlagImageUrl(item.code),"alt":"","width":"20"}}),_c('span',{staticStyle:{"margin-right":"4px","color":"black"}},[_vm._v("("+_vm._s(item.calling_code)+"+)")]),_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(item.country_name))])])]}}],null,false,3500810801),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }