





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';
import { MONTHS } from '@/modules/constants';
import { Tariff, TariffTableRow } from '@/modules/Types';


@Component({
    components: {}
})

export default class ConfigTariffTab extends Vue {

    @Global.State('lang') lang;

    @Prop() tou_tariff_prop: Tariff;
    @Prop() is_admin: boolean;

    search = '';
    items = [];
    tariff_dialog = false;
    dialog_mode = '';

    get headers() {
        const columns = [
            {text: 'Year', value: 'year', sortable: false},
            {text: 'Period', value: 'period', sortable: false},
            {text: 'Tariff 1 (Peak)', value: 'peak', sortable: false}, 
            {text: 'Tariff 2 (High)', value: 'high', sortable: false}, 
            {text: 'Tariff 3 (Low)', value: 'low', sortable: false}
        ];
        
        return this.is_admin
            ? columns.concat([{text: 'Action', value: 'action', sortable: false }])
            : columns;
    }

    mounted() {
        this.generateTableData();
    }

    customFilter(items, search) {
      if (!search) return items;

      const lowerSearch = search.toLowerCase();
      return (items.filter((item) =>
        item.year.toString().toLowerCase().includes(lowerSearch) ||
        (item.period && item.period.toLowerCase().includes(lowerSearch)) ||
        (item.months && item.months.map((month) => this.$t(MONTHS[month])).some((month_text) => month_text.toLowerCase().includes(lowerSearch)))
      ));
    }

    @Watch('tou_tariff_prop')
    generateTableData() {
        const items: TariffTableRow[] = [];
        Object.entries(this.tou_tariff_prop).forEach(([year, tariff]) => {
            tariff.forEach((tariff_data, index) => {
                const item: TariffTableRow = {...tariff_data, year, period: this.getPeriodsText(tariff_data.months), class: 'body-2'};
                if (index === 0) {
                    item.show_year = true;
                }
                items.push(item);
            });
        });
        items.sort((item1, item2) => +item2.year - +item1.year);
        this.items = items;
    }

    getPeriodsText(months) {
        if (!months.length) return '';

        let start = months[0], end = months[0];
        let periods = [];

        months.slice(1).forEach((month_num) => {
            if (month_num - 1 === end) {
                end = end + 1;
            }else {
                periods = periods.concat(this.getNewPeriod(start, end));
                start = end = month_num;
            }
        });
        periods = periods.concat(this.getNewPeriod(start, end));

        return periods.join(', ');
    }

    getNewPeriod(start, end){
        if (start === end){
            return this.$t(MONTHS[start]);
        }else if (start + 1 === end){
            return [this.$t(MONTHS[start]), this.$t(MONTHS[end])];
        }
        return `${this.$t(MONTHS[start])} - ${this.$t(MONTHS[end])}`;
    }

    editSelectedTOU(selected) {
        this.$emit('setSelectedTab', 'edit');
        this.$emit('editSelectedTOU', selected);
    }

    deleteSelectedTOU(item) {
        this.$emit('deleteSelectedTOU', item);
    }
}

