import { render, staticRenderFns } from "./ProjectCabinetTable.vue?vue&type=template&id=1723aecf&scoped=true"
import script from "./ProjectCabinetTable.vue?vue&type=script&lang=ts"
export * from "./ProjectCabinetTable.vue?vue&type=script&lang=ts"
import style0 from "./ProjectCabinetTable.vue?vue&type=style&index=0&id=1723aecf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1723aecf",
  null
  
)

export default component.exports