



































































import Vue from 'vue';
import Component from 'vue-class-component';
import { User, Global } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import { Prop, Watch } from 'vue-property-decorator';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PropEditor from '@/components/PropEditor.vue';
import { updateUserProfile } from '@/modules/ApiUsers';
import CountryPhoneInput from '@/components/CountryPhoneInput.vue';
import Utils from '@/modules/Utils';
import { isEqual } from 'lodash';

@Component({
    components: {
        ConfirmDialog,
        PropEditor,
        CountryPhoneInput
    }
})

export default class EditUserProfileDialog extends Vue {

    @User.State('user_profile') user_profile;
    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;

    @Prop() dialog;

    editable_user_profile = null;
    isUpdateDisabled = false;

    mounted(){
        this.editable_user_profile = cloneDeep(this.user_profile) || {
            first_name: '',
            last_name: '',
            phone_number: ''
        };

        this.updateDisabledStatus();
    }

    @Watch('user_profile')
    updateUserData() {
        if (!this.user_profile) return;

        if (!isEqual(this.user_profile, this.editable_user_profile)) {
            this.editable_user_profile = cloneDeep(this.user_profile);
        }
    }

    get dialog_max_width() {
        return this.$vuetify.breakpoint.xsOnly 
            ? '80vw' 
            : this.$vuetify.breakpoint.smOnly 
                ? '50vw' 
                : this.$vuetify.breakpoint.mdOnly 
                    ? '40vw' 
                    : this.$vuetify.breakpoint.lgOnly 
                        ? '30vw' 
                        : '25vw';
    }

    isRequired(value) {
        return !!value || this.$t('Required');
    }

    checkFirstName(value) {
        if (!value) return true;
        return Utils.isValidName(value, 'First');
    }

    checkLastName(value) {
        if (!value) return true;
        return Utils.isValidName(value, 'Last');
    }

    @Watch('editable_user_profile.first_name')
    @Watch('editable_user_profile.last_name')
    @Watch('editable_user_profile.phone_number')
    updateDisabledStatus() {
        this.isUpdateDisabled = this.$refs.form 
            ? !(this.$refs.form as Vue & { validate: () => boolean }).validate()
            : true;
    }

    phoneNumberChanged(value) {
        this.editable_user_profile.phone_number = value;
    }
    
    async editProfile() {
        this.editable_user_profile.first_name = this.editable_user_profile.first_name.trim();
        this.editable_user_profile.last_name = this.editable_user_profile.last_name.trim();
        this.editable_user_profile.phone_number = this.editable_user_profile.phone_number.trim();

        const user_data = {
            first_name: this.editable_user_profile.first_name,
            last_name: this.editable_user_profile.last_name,
            phonenumber: this.editable_user_profile.phone_number,
            multi_factor_auth: `${this.editable_user_profile.multi_factor_auth}`,
            multi_factor_auth_method: this.editable_user_profile.multi_factor_auth_method || 'Email',
            email: this.editable_user_profile.email
        };

        const succeeded = await updateUserProfile(this.editable_user_profile.company, this.editable_user_profile.id, user_data);
        if (succeeded) {
            this.throwNotify({
              type: 'success',
              title: this.$t('Success'),
              text: this.$t(`User data updated successfully`)
            });
        }else {
            this.throwNotify({
              type: 'error',
              title: this.$t('Error'),
              text: this.$t(`Some of the user's information is not saved`)
            });
        }

        this.$emit('close');
    }
}
